import { createSlice } from "@reduxjs/toolkit";
import { getTeamMembers } from "actions/teamMembersAction";

const initialState = {
  edit: "",
  teams: [],
  count: 0,
  status: null,
}

export const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {
    setAllTeams(state, { payload }) {
      state.teams = payload.teams;
      state.count = payload.count;
    },
    setEditTeamMembersId(state, payload) {
      state.edit = payload.payload;
    },
  },
  extraReducers: {
    [getTeamMembers.pending]: (state) => {
      state.status = "loading";
    },
    [getTeamMembers.fulfilled]: (state, { payload }) => {
      state.teams = payload.teams;
      state.status = "solved";
    },
    [getTeamMembers.rejected]: (state) => {
      state.status = "rejected";
    },
  },
})

export const { setEditTeamMembersId, setAllTeams } = teamMembersSlice.actions;

export default teamMembersSlice.reducer;