import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { serviceApi } from "service/service";

import { FormCreator } from "components";
import { serviceSchema } from "./serviceSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditService = () => {
  const location = useLocation();

  const editServiceId = useSelector(state => state.service.edit._id);
  const serviceType = useSelector(state => state.serviceType.allServiceType);
  const editContent = useSelector((state) => state.service.edit);

  const [createNewService, editService] = useActionHelper({
    createNewItemApiCall: serviceApi.createNewService,
    updateItemApiCall: serviceApi.updateService,
    editId: editServiceId,
  });

  const apiCall = location.pathname.includes("create") ? createNewService : editService;

  const [initialValues, validation] = formikValidation(serviceSchema);

  return (
    <FormCreator
      pageName="service"
      apiCall={apiCall}
      options={serviceType}
      schema={serviceSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
