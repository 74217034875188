import { getTechnologies, deleteTechnology } from "actions/technologiesAction";
import { setEditTechnologiesId } from "features/technologiesSlice";
import SectionBody from "components/SectionBody/SectionBody";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "hooks/useSearchParams";

const Technology = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const technologies = useSelector((state) => state.technologies.technologies);
  const count = useSelector((state) => state.technologies.count);
  const dispatch = useDispatch();

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const createHandler = () => {
    dispatch(setEditTechnologiesId(""));
    navigate("create");
  };

  useEffect(() => {
    dispatch(getTechnologies({ currentPage, pageLimit, search }));
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={technologies}
          deleteHandler={deleteTechnology}
          createHandler={createHandler}
          setEditId={setEditTechnologiesId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isDescription={false}
          count={count}
          showPagination={isPageination}
          isRound={true}
        />
      </div>
    </div>
  );
};

export default Technology;
