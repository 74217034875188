import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: "",
  allPortfolios: [],
  allPortfoliosWithoutPage: [],
  count: 0,
};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setAllPortfoliosWithoutPage(state, { payload }) {
      state.allPortfoliosWithoutPage = payload.projects;
    },
    setAllPortfolios(state, { payload }) {
      state.allPortfolios = payload.projects;
      state.count = payload.count;
    },
    setEditPortfolioId(state, payload) {
      state.edit = payload.payload;
    },
    clearEditPortfolio(state) {
      state.edit = "";
    },
  },
});

export const {
  setAllPortfoliosWithoutPage,
  setAllPortfolios,
  setEditPortfolioId,
  clearEditPortfolio,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
