import React from "react";
import { useSelector } from "react-redux";

import Header from "layoutComponents/Header";

import clsx from 'clsx';
import Sidebar from 'layoutComponents/Sidebar';

const LeftSidebar = ({ children }) => {
  const { sidebarToggle, sidebarToggleMobile, sidebarFixed } = useSelector((state) => state.sidebar);
  const { headerFixed, headerSearchHover, headerDrawerToggle } = useSelector((state) => state.header);
  const { footerFixed } = useSelector((state) => state.footer);
  const { contentBackground } = useSelector((state) => state.main);

  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <Header />
        </div>
        <div className="app-main">
          <Sidebar />
          <div className="app-content">
            <div className="app-content--inner px-0">
              <div className="app-content--inner__wrapper px-0">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
