import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamDetailsApi } from "service/teamDetails";

export const getTeamDetails = createAsyncThunk(
  "/teamDetails/getTeamDetails",
  async (_, { rejectWithValue }) => {
    try {
      const res = await teamDetailsApi.allTeamDetailAdmin();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
