import instance, { formDataInstance } from "./baseRequest";

export const testimonialsApi = {
  createNewTestimonial: async (data) =>
    await formDataInstance.post("/admin/testimonials/store", data),
  updateTestimonial: async (id, data) =>
    await formDataInstance.put(`/admin/testimonials/update/${id}`, data),
  deleteTestimonial: async (id) => {
    return await instance.delete(`/admin/testimonials/delete/${id}`);
  },
  getTestimonialById: async (id) => {
    return await instance.get(`/v1/testimonials/${id}`);
  },
  allTestimonialAdmin: async ({ page, limit, search }) => {
    return await instance.get("/admin/testimonials", {
      params: {
        page,
        limit,
        search,
      },
    });
  },
  getAllTestimonial: async (page, limit) => {
    return await instance.get(`/v1/testimonials`, {
      params: {
        page,
        limit,
      },
    });
  },
};
