export const styles = theme => ({
    cropper: {
      position: 'absolute',
      zIndex: 100,
      width: '50vw',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    minHeight: '50vh',
    background: '#333',
    [theme?.breakpoints?.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    color: 'white',
    backgroundColor: '#d6244b',
    border: 'none',
    padding: '10px 0 10px 10px',
    borderRadius: '7px',
  },
  cropCloseButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    zIndex: '1',
    border: 'none',
    padding: '5px 0 5px 5px',
    backgroundColor: '#d6244b',
    borderRadius: '7px',
    marginBottom: "10px",
    marginLeft: 'auto',
  },
  cropCloseButtonPosition: {
    position: 'absolute !important',
  },
  saveSpan: {
    color: 'white',
  },
  closeSpan: {
    color: 'white',
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme?.breakpoints?.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme?.breakpoints?.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme?.breakpoints?.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})
