import instance from "./baseRequest";

export const categoryApi = {
  createNewCategory: async (data) => {
    await instance.post('/admin/categories/store', data);
  },
  updateCategory: async (id, data) => {
    return await instance.put(`/admin/categories/update/${id}`, data);
  },
  deleteCategory: async (id) => {
    return await instance.delete(`/admin/categories/delete/${id}`);
  },
  allCategorysAdmin: async (page, limit) => {
    return await instance.get('/admin/categories', {
      params: {
        page,
        limit
      }
    });
  },
  allCategorys: async (page, limit) => {
    return await instance.get('/v1/categories', {
      params: {
        page,
        limit
      }
    });
  },
}