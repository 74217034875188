import { createSlice } from "@reduxjs/toolkit";
import { getProjects } from "actions/projectsAction";


const initialState = {
  projects: []
}

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: {
    [getProjects.pending]: (state) => {
      state.getTestimonialsLoader = true;
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.projects = payload.projects;
      state.getTestimonialsLoader = false;
    },
    [getProjects.rejected]: (state) => {
      state.getTestimonialsLoader = false;
    },
  }
})

export const selectTestimonials = (state) => state.testimonials;

export default projectsSlice.reducer;