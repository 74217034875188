import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const teamContentSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "what_we_do",
    name: "what_we_do",
    label: CONSTANTS.WHAT_WE_DO,
    type: "array",
    placeholder: CONSTANTS.ENTER_WHAT_WE_DO,
    backend_field: "what_we_do",
    validation: Yup.array().of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
  {
    id: "what_we_dont",
    name: "what_we_dont",
    label: CONSTANTS.WHAT_WE_DONT,
    type: "array",
    placeholder: CONSTANTS.ENTER_WHAT_WE_DONT,
    backend_field: "what_we_dont",
    validation: Yup.array().of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
  {
    id: "images",
    name: "images",
    label: CONSTANTS.IMAGES,
    type: "imageArray",
    placeholder: CONSTANTS.ENTER_IMAGES,
    backend_field: "images",
    validation: Yup.array().of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
];
