import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: '',
  allCareers: [],
  count: 0,
};

export const careerSlice = createSlice({
  name: "career",
  initialState,
  reducers: {
    setAllCareers(state, { payload }) {
      state.allCareers = payload.careers;
      state.count = payload.count;
    },
    setEditCareerId(state, payload) {
      state.edit = payload.payload;
    },
    clearEditCareer(state) {
      state.edit = '';
    },
  },
});

export const {
  setAllCareers,
  setEditCareerId,
  clearEditCareer,
} = careerSlice.actions;

export default careerSlice.reducer;
