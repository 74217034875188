import { createSlice } from "@reduxjs/toolkit";
import { getAbout } from "actions/aboutNextStackAction";

const initialState = {
  edit: "",
  about: [],
  status: null,
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    setEditAboutId: (state, payload) => {
      state.edit = payload.payload;
    }
  },
  extraReducers: {
    [getAbout.pending]: (state) => {
      state.status = true;
    },
    [getAbout.fulfilled]: (state, { payload }) => {
      state.status = false;
      state.about = payload.about;
    },
    [getAbout.rejected]: (state) => {
      state.status = false;
    },
  },
});

export const { setEditAboutId } = aboutSlice.actions;

export default aboutSlice.reducer;