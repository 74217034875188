import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateEditServiceType } from "./CreateEditServiceType";
import MultiSelect from "components/MultiSelect/MultiSelect";
import ItemsList from "components/ItemsList/ItemsList";
import {
  setAllServiceType,
  setEditServiceTypeId,
} from "features/serviceTypeSlice";
import { serviceTypeApi } from "service/serviceType";
import { deleteServiceType } from "actions/serviceTypeAction";

import style from "./ServiceType.module.scss";

const ServiceType = ({ setFilteredType }) => {
  const dispatch = useDispatch();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [names, setNames] = useState([]);
  const serviceType = useSelector((state) => state.serviceType.allServiceType);

  const createHandler = () => {
    setIsCreateEdit(true);
    setIsEdit(false);
    dispatch(setEditServiceTypeId(""));
  };

  const viewHandler = () => {
    setIsViewMode(true);
  };

  const closeHandel = () => {
    setIsViewMode(false);
  };

  const handleEdit = (data) => {
    setIsCreateEdit(true);
    setIsEdit(true);
    dispatch(setEditServiceTypeId(data));
  };

  useEffect(() => {
    const fetchBlog = async () => {
      const { data } = await serviceTypeApi.allServiceTypeAdmin(0, 50);
      return data;
    };

    fetchBlog().then((res) => {
      dispatch(setAllServiceType(res.serviceTypes));
    });
  }, []);

  useEffect(() => {
    setNames([]);
    serviceType?.forEach((serviceType) => {
      const newServiceType = {
        title: serviceType.title,
        _id: serviceType._id,
      };
      setNames((prev) => [...prev, newServiceType]);
    });
  }, [serviceType]);

  return (
    <>
      {isViewMode && (
        <ItemsList
          closeHandel={closeHandel}
          handleDelete={deleteServiceType}
          handleEdit={handleEdit}
          categories={serviceType}
          createHandler={createHandler}
          buttonName="Create Service Type"
        />
      )}

      <div className={style.service_type_wrapper}>
        <MultiSelect names={names} setFilteredType={setFilteredType} />

        <div className={style.buttons_wrapper}>
          <div className={style.create_button} onClick={viewHandler}>
            View Service Type
          </div>
        </div>
      </div>
      <div className={style.create_edit_service_type}>
        {isCreateEdit && (
          <CreateEditServiceType
            isEdit={isEdit}
            setIsCreateEdit={setIsCreateEdit}
          />
        )}
      </div>
    </>
  );
};

export default ServiceType;
