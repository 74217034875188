import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarFixed: true,
  sidebarFooter: true,
  sidebarShadow: false,
  sidebarStyle: "app-sidebar--dark",
  sidebarUserbox: true,
  sidebarToggleMobile: false,
  sidebarToggle: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarShadow(state, sidebarShadow) {
      state.sidebarShadow = sidebarShadow.payload;
    },
    setSidebarFixed(state, sidebarFixed) {
      state.sidebarFixed = sidebarFixed.payload;
    },
    setSidebarStyle(state, sidebarStyle) {
      state.sidebarStyle = sidebarStyle.payload;
    },
    setSidebarFooter(state, sidebarFooter) {
      state.sidebarFooter = sidebarFooter.payload;
    },
    setSidebarToggleMobile(state, sidebarToggleMobile) {
      state.sidebarToggleMobile = sidebarToggleMobile.payload;
    },
    setSidebarToggle(state, sidebarToggle) {
      state.sidebarToggle = sidebarToggle.payload;
    },
    setSidebarUserbox(state, sidebarUserbox) {
      state.sidebarUserbox = sidebarUserbox.payload;
    },
  },
});

export const {
  setSidebarShadow,
  setSidebarFixed,
  setSidebarStyle,
  setSidebarFooter,
  setSidebarToggleMobile,
  setSidebarToggle,
  setSidebarUserbox,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
