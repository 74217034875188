import React, { useState } from "react";
import style from "../formCreator.module.scss";
import { Delete, UploadFile } from "@mui/icons-material";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

const ImageSection = ({
  imageArray,
  setImageArray,
  elem,
  handleAddFile,
  setShowImage,
  setShowIcon,
  handleDeleteFormFieldArrayItem,
  multiFilePage = false,
}) => {
  const [currentCard, setCurrentCard] = useState();

  const dragStartHandler = (e, value) => {
    setCurrentCard(value);
  };
  const dragLeaveHandler = (e) => {
    e.target.style.opacity = '1';
  };
  const dragEndHandler = (e) => {
    e.target.style.opacity = '1';
  };
  const dragOverHandler = (e) => {
    e.preventDefault();
    e.target.style.opacity = '0.5';
  };
  const dropHandler = (e, value) => {
    e.preventDefault();
    e.target.style.opacity = '1';
    const updatedImageArray = [...imageArray];
    const dragIndex = imageArray.indexOf(currentCard);
    const dropIndex = imageArray.indexOf(value);
    const removedElement = updatedImageArray.splice(dragIndex, 1);
    updatedImageArray.splice(dropIndex, 0, ...removedElement)
    setImageArray(updatedImageArray);
  };

  return (
    <div className={style.array_wrapper_image}>
      {imageArray?.map((value, index) => (
        <div
          onDragStart={e => dragStartHandler(e, value)}
          onDragLeave={e => dragLeaveHandler(e)}
          onDragEnd={e => dragEndHandler(e)}
          onDragOver={e => dragOverHandler(e)}
          onDrop={e => dropHandler(e, value)}
          draggable
          className={style.image_wrapper}
          key={index}>
          <label
            className={style.testimonial_image}
            htmlFor={index}
          >
            {imageArray[index] ? (
              <img
                src={
                  typeof value === "string"
                    ? `${image_url}${imageArray[index]}`
                    : URL.createObjectURL(imageArray[index])
                }
                alt="avatar"
              />
            ) : (
              <UploadFile />
            )}
          </label>
          <input
            id={index}
            name={`${elem.backend_field}[${index}]`}
            type="file"
            className={style.testimonials_image_input}
            onChange={(event) => {
              handleAddFile(event, index);

              if (elem.id === "images")
                if(!multiFilePage) {
                  setShowImage(imageArray[index]);
                }
              if (elem.id === "icon")
                setShowIcon(imageArray[index]);
            }}
          />
          <Delete
            onClick={() =>
              handleDeleteFormFieldArrayItem(
                elem.backend_field,
                index
              )
            }
          />
        </div>
      ))}
    </div>
  )
}

export default ImageSection;
