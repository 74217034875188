import { createAsyncThunk } from "@reduxjs/toolkit";
import Notify from "helpers/Notify";
import { testimonialsApi } from "service/testimonials";
import { CONSTANTS } from "constants/general";

export const getTestimonials = createAsyncThunk(
  "/testimonials",
  async ({ search, currentPage, pageLimit }, { rejectWithValue }) => {
    try {
      const res = await testimonialsApi.allTestimonialAdmin({
        search,
        page: currentPage,
        limit: pageLimit,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateTestimonials = createAsyncThunk(
  "/testimonials",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await testimonialsApi.updateTestimonial(id, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteTestimonials = createAsyncThunk(
  "/testimonials",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await testimonialsApi.deleteTestimonial(data.id);

      const { pageLimit, search, currentPage } = data;

      Notify(CONSTANTS.SUCCESS, CONSTANTS.REMOVED_TESTIMONIAL);
      dispatch(getTestimonials({ pageLimit, search, currentPage }));
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);
