import { createSlice } from "@reduxjs/toolkit";
import { getAllCategories } from "actions/categoriesAction";

const initialState = {
  edit: '',
  allCategories: [],
  status: "",
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setAllCategories(state, payload) {
      state.allCategories = payload.payload;
    },
    setEditCategoryId(state, payload) {
      state.edit = payload.payload;
    },

    clearEditCategory(state) {
      state.edit = '';
    },
  },
  extraReducers: {
    [getAllCategories.pending]: (state) => {
      state.status = "loading";
    },
    [getAllCategories.fulfilled]: (state, { payload }) => {
      state.allCategories = payload?.categories;
      state.status = "solved";
    },
    [getAllCategories.rejected]: (state) => {
      state.status = "rejected";
    },
  }
});

export const {
  setAllCategories,
  setEditCategoryId,
  clearEditCategory,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;