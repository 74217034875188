import instance from "./baseRequest";

export const contactApi = {
  createNewContact: async (data) => {
    await instance.post('/admin/contacts/store', data);
  },
  updateContact: async (id, data) => {
    return await instance.put(`/admin/contacts/update/${id}`, data);
  },
  deleteContact: async (id) => {
    return await instance.delete(`/admin/contacts/delete/${id}`);
  },
  getContactById: async (id) => {
    return await instance.get(`/v1/contacts/${id}`);
  },
  allContactAdmin: async (page, limit) => {
    return await instance.get('/admin/contacts', {
      params: {
        page,
        limit
      }
    });
  },
  getAllContact: async (page, limit) => {
    return await instance.get(`/v1/contacts`, {
      params: {
        page,
        limit
      }
    });
  },
}