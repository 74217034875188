import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageTitleStyle: "",
  pageTitleBackground: "",
  pageTitleShadow: false,
  pageTitleIconBox: true,
  pageTitleDescription: true,
};

export const pageTitleSlice = createSlice({
  name: "pageTitleSlice",
  initialState,
  reducers: {
    setPageTitleStyle(state, pageTitleStyle) {
      state.pageTitleStyle = pageTitleStyle.payload;
    },
    setPageTitleBackground(state, pageTitleBackground) {
      state.pageTitleBackground = pageTitleBackground.payload;
    },
    setPageTitleShadow(state, pageTitleShadow) {
      state.pageTitleShadow = pageTitleShadow.payload;
    },
    setPageTitleIconBox(state, pageTitleIconBox) {
      state.pageTitleIconBox = pageTitleIconBox.payload;
    },
    setPageTitleDescription(state, pageTitleDescription) {
      state.pageTitleDescription = pageTitleDescription.payload;
    },
  },
});

export const {
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleIconBox,
  setPageTitleDescription,
} = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
