import instance, {formDataInstance} from "./baseRequest";

export const blogApi = {
  createNewBlog: async (data) => {
    await formDataInstance.post('/admin/blogs/store', data);
  },
  updateBlog: async (id, data) => {
   return await formDataInstance.put(`/admin/blogs/update/${id}`, data);
  },
  deleteBlog: async (id) => {
    return await instance.delete(`/admin/blogs/delete/${id}`);
  },
  getBlogById: async (id) => {
    return await instance.get(`/v1/blogs/${id}` );
  },
  allBlogsAdmin: async (page, limit, search = null) => {
    return await instance.get('/admin/blogs', {
      params: {
        page,
        limit,
        search
      }
    });
  },
  allBlogs: async (page, limit) => {
    return await instance.get('/v1/blogs', {
      params: {
        page,
        limit
      }
    });
  },
  relatedBlogArticles: async () => {
    return await instance.get('/v1/blogs/related/articles');
  }
}