import { createSlice } from "@reduxjs/toolkit";
import { getServiceType } from "actions/serviceTypeAction";

const initialState = {
  edit: '',
  allServiceType: [],
  status: "",
};

export const serviceTypeSlice = createSlice({
  name: "serviceType",
  initialState,
  reducers: {
    setAllServiceType(state, payload) {
      state.allServiceType = payload.payload;
    },
    setEditServiceTypeId(state, payload) {
      state.edit = payload.payload;
    },

    clearEditServiceType(state) {
      state.edit = '';
    },
  },
  extraReducers: {
    [getServiceType.pending]: (state) => {
      state.status = "loading";
    },
    [getServiceType.fulfilled]: (state, { payload }) => {
      state.allServiceType = payload?.serviceTypes;
      state.status = "solved";
    },
    [getServiceType.rejected]: (state) => {
      state.status = "rejected";
    },
  }
});

export const {
  setAllServiceType,
  setEditServiceTypeId,
  clearEditServiceType,
} = serviceTypeSlice.actions;

export default serviceTypeSlice.reducer;