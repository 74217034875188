import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Button } from "@mui/material";

import { getServiceType } from "actions/serviceTypeAction";
import Image from "components/Image";

const ViewService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.service?.edit);
  const serviceType = useSelector(
    (state) => state?.serviceType?.allServiceType
  );

  const relatedServices = serviceType?.find(
    (service) => service?._id === data?.service_type
  );

  const handleCloseViewPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getServiceType());
  }, []);

  return (
    <div className="view_field">
      <div className="view_cancel_button">
        <Button onClick={handleCloseViewPage}>Back</Button>
      </div>
      <div className="view_contant">
        <div className="view_title">
          <h2>Service Details</h2>
        </div>
        <div className="view_container">
          <div className="view_subtitle">Image</div>
          <div className="view_image_filed">
            <Image image={data?.image} />
          </div>
          <div className="view_subtitle">Icon</div>
          <div className="view_image_filed">
            <Image image={data?.icon} />
          </div>
          <div className="view_subtitle">Title</div>
          <div className="view_text">{data?.title}</div>
          <div className="view_subtitle">Description</div>
          <div className="view_text">{data?.description}</div>
          <div className="view_subtitle">Short description</div>
          <div className="view_text">{data?.short_description}</div>
          <div className="view_subtitle">Main Title</div>
          <div className="view_text">{data?.main_title}</div>
          <div className="view_subtitle">Service Type</div>
          <div className="view_text">{relatedServices?.title}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewService;
