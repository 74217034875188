import React from 'react';

import style from './Card.module.scss';

const Card = ({children}) => {
  return(
    <div className={style.card_wrapper}>
      {children}
    </div>
  )
}

export default Card;
