import imageNotFound from "assets/images/imageNotFound.jpeg";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

const Image = (props) => {
  const {
    image,
    styles = "view_image",
    notFoundImage = imageNotFound,
    socialStyles,
  } = props;
  return (
    <img
      src={image ? `${image_url}${image}` : imageNotFound}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = notFoundImage;
      }}
      alt="blog"
      className={`${styles} ${socialStyles}`}
    />
  );
};

export default Image;
