import { createAsyncThunk } from "@reduxjs/toolkit";
import { technologyApi } from "service/technology";
import Notify from "helpers/Notify";

export const getTechnologies = createAsyncThunk(
  "/technologies/getTechnologies",
  async ({currentPage, pageLimit, search}, { rejectWithValue }) => {
    try {
      const res = await technologyApi.allTechnologyAdmin(currentPage, pageLimit, search);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteTechnology = createAsyncThunk(
  "/technologies/deleteTechnology",
  async ({id, pageLimit, currentPage},  { rejectWithValue, dispatch }) => {
    try {
      const res = await technologyApi.deleteTechnology(id);

      Notify("success", "Removed technology");
      dispatch(getTechnologies({currentPage, pageLimit}));
    } catch (err) {
        return rejectWithValue(err.message)
    }
  }
);
