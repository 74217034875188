import { createSlice } from "@reduxjs/toolkit";
import { getTechnologies } from "actions/technologiesAction";

const initialState = {
  edit: "",
  technologies: [],
  count: 0,
  status: null,
};

export const technologiesSlice = createSlice({
  name: "technologies",
  initialState,
  reducers: {
    setEditTechnologiesId(state, payload) {
      state.edit = payload.payload;
    },
  },
  extraReducers: {
    [getTechnologies.pending]: (state) => {
      state.status = "loading";
    },
    [getTechnologies.fulfilled]: (state, { payload }) => {
      state.technologies = payload.technologies;
      state.count = payload.count;
      state.status = "solved";
    },
    [getTechnologies.rejected]: (state) => {
      state.status = "reject";
    },
  },
});

export const { setEditTechnologiesId } = technologiesSlice.actions;

export default technologiesSlice.reducer;