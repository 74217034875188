import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { useActionHelper } from "hooks/useActionHelper";
import { testimonialsApi } from "service/testimonials";

import { FormCreator } from "components";
import { getProjects } from "actions/projectsAction";
import { testimonialsSchema } from "./testimonialsSchema";
import { onFileCropperChange } from "helpers";
import { formikValidation } from "helpers/formikValidation";
import { errMessagesObj, fieldNamesObj } from "constants/general";

export const CreateEditTestimonial = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState(null);
  const editTestimonialId = useSelector((state) => state.testimonials.edit._id);
  const projects = useSelector((state) => state.projects.projects);
  const editContent = useSelector((state) => state.testimonials.edit);
  const [isCustomChecked, setIsCustomChecked] = useState(false);

  const [createNewTestimonial, editTestimonial] = useActionHelper({
    createNewItemApiCall: testimonialsApi.createNewTestimonial,
    updateItemApiCall: testimonialsApi.updateTestimonial,
    editId: editTestimonialId,
  });

  const apiCall = location.pathname.includes("create")
    ? createNewTestimonial
    : editTestimonial;

  const [initialValues, validation] = formikValidation(testimonialsSchema);

  const validationSchema = Yup.object().shape({
    ...validation,
    project_id: isCustomChecked
      ? Yup.string()
      : Yup.string().required(errMessagesObj.requiredProjectId),
    project_name: isCustomChecked
      ? Yup.string().required(errMessagesObj.requiredProjectName)
      : Yup.string(),
  });

  const checkCropperMode = (values, showImage) => {
    values.image = showImage || editContent.image;
  };

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  return (
    <FormCreator
      pageName="testimonials"
      apiCall={apiCall}
      options={projects}
      isCropper={true}
      schema={testimonialsSchema}
      editContent={editContent}
      onFileCropperChange={onFileCropperChange}
      imageSrc={imageSrc}
      setImageSrc={setImageSrc}
      checkCropperMode={checkCropperMode}
      initialValues={initialValues}
      validation={validation}
      validSchema={validationSchema}
      isCustomChecked={isCustomChecked}
      setIsCustomChecked={setIsCustomChecked}
      customKey={fieldNamesObj.projectName}
      notCustomKey={fieldNamesObj.projectId}
    />
  );
};
