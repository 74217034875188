import { Routes, Route } from "react-router";
import { useSelector } from "react-redux";

import Login from "pages/login";
import LeftSidebar from "LeftSidebar";
import HomePage from "pages/HomePage";

import {
  Blog,
  CreateEditBlog,
  ViewBlog,
  Career,
  CreateEditCareer,
  NextStack,
  OurValues,
  Services,
  CreateEditService,
  Portfolio,
  CreateEditPortfolio,
  Pages,
  CreateEditPage,
  CreateEditOurValue,
  CreateEditTestimonial,
  Testimonials,
  ViewTestimonial,
  TeamDetails,
  TeamMembers,
  CreateEditTeamMember,
  Contacts,
  CreateEditTechnologies,
  Technology,
  CreateEditRecognitions,
  Recognition,
  ViewPortfolio,
  ViewService,
  ViewCareer,
  SocialLinks,
  CreateEditSocialLinks,
  FormCategories,
  ChangePassword,
} from "pages";

const PageRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {!isAuth ? (
        <Routes>
          <Route path="/*" element={<Login />} />
        </Routes>
      ) : (
        <LeftSidebar>
          <Routes>
            <Route path="/*" element={<HomePage />} />

            <Route path="/home/testimonials" element={<Testimonials />} />
            <Route
              path="/home/testimonials/create"
              element={<CreateEditTestimonial />}
            />
            <Route
              path="/home/testimonials/edit"
              element={<CreateEditTestimonial />}
            />
            <Route path="/home/testimonials/view" element={<ViewTestimonial />} />

            <Route path="/home/technologies" element={<Technology />} />
            <Route
              path="/home/technologies/create"
              element={<CreateEditTechnologies />}
            />
            <Route
              path="/home/technologies/edit"
              element={<CreateEditTechnologies />}
            />

            <Route path="/home/recognitions" element={<Recognition />} />
            <Route
              path="/home/recognitions/create"
              element={<CreateEditRecognitions />}
            />
            <Route
              path="/home/recognitions/edit"
              element={<CreateEditRecognitions />}
            />

            <Route path="/about/nextstack" element={<NextStack />} />
            <Route path="/about/our-values" element={<OurValues />} />
            <Route
              path="/about/our-values/create"
              element={<CreateEditOurValue />}
            />
            <Route
              path="/about/our-values/edit"
              element={<CreateEditOurValue />}
            />

            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/create" element={<CreateEditBlog />} />
            <Route path="/blog/edit" element={<CreateEditBlog />} />
            <Route path="/blog/view" element={<ViewBlog />} />

            <Route path="/career" element={<Career />} />
            <Route path="/career/create" element={<CreateEditCareer />} />
            <Route path="/career/edit" element={<CreateEditCareer />} />
            <Route path="/career/view" element={<ViewCareer />} />

            <Route path="/service/*" element={<Services />} />
            <Route path="/service/create" element={<CreateEditService />} />
            <Route path="/service/edit" element={<CreateEditService />} />
            <Route path="/service/view" element={<ViewService />} />

            <Route path="/portfolio/*" element={<Portfolio />} />
            <Route path="/portfolio/create" element={<CreateEditPortfolio />} />
            <Route path="/portfolio/edit" element={<CreateEditPortfolio />} />
            <Route path="/portfolio/view" element={<ViewPortfolio />} />

            <Route
              path="/pages"
              element={<Pages showFilter={false} showPagination={false} />}
            />
            <Route path="/pages/create" element={<CreateEditPage />} />
            <Route path="/pages/edit" element={<CreateEditPage />} />

            <Route path="/team/team-details" element={<TeamDetails />} />

            <Route path="/team/our-team" element={<TeamMembers />} />
            <Route
              path="/team/our-team/create"
              element={<CreateEditTeamMember />}
            />
            <Route
              path="/team/our-team/edit"
              element={<CreateEditTeamMember />}
            />

            <Route path="/contacts/details" element={<Contacts />} />

            <Route path="/contacts/social-links" element={<SocialLinks />} />
            <Route
              path="/contacts/social-links/create"
              element={<CreateEditSocialLinks />}
            />
            <Route
              path="/contacts/social-links/edit"
              element={<CreateEditSocialLinks />}
            />
            <Route
              path="/contacts/form-category"
              element={<FormCategories />}
            />
            <Route
              path="/change-password"
              element={<ChangePassword />}
            />
          </Routes>
        </LeftSidebar>
      )}
    </>
  );
};

export default PageRoutes;
