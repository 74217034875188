import instance from "./baseRequest";

export const aboutItemApi = {
  createNewAboutItem: async (data) => {
    await instance.post('/admin/about/about-items/store', data);
  },
  updateAboutItem: async (id, data) => {
    return await instance.put(`/admin/about/about-items/update/${id}`, data);
  },
  deleteAboutItem: async (id) => {
    return await instance.delete(`/admin/about/about-items/delete/${id}`);
  },
  allAboutItemAdmin: async (page, limit, search) => {
    return await instance.get('/admin/about/about-items/', {
      params: {
        page,
        limit,
        search,
      }
    });
  },
}
