import axios from "axios";

import Notify from "helpers/Notify";

const base_url = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: base_url,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
      sessionStorage.removeItem("access_token");
      window.location.reload();
    }
    Notify("error", error?.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const formDataInstanceCreator = async (url, data, method = "post") =>
  await instance[method](url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const formDataInstance = {
  post: async (url, data) => formDataInstanceCreator(url, data),
  put: async (url, data) => formDataInstanceCreator(url, data, "put"),
};

export default instance;
