import { createSlice } from "@reduxjs/toolkit";
import { getFormCategories } from "actions/formAction";

const initialState = {
  edit: "",
  allFormCategories: [],
  count: 0,
  status: null,
}

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormId: (state, { payload }) => {
      state.edit = payload;
    },
  },
  extraReducers: {
    [getFormCategories.pending]: (state) => {
      state.status = true;
    },
    [getFormCategories.fulfilled]: (state, { payload }) => {
      state.allFormCategories = payload.formCategories;
      state.count = payload.count;
      state.status = false;
    },
    [getFormCategories.rejected]: (state) => {
      state.status = false;
    },
  },
});

export const { setFormId } = formSlice.actions;

export default formSlice.reducer;