import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { portfolioApi } from "service/project";
import { getServiceType } from "actions/serviceTypeAction";
import { categoryApi } from "service/categories";
import { setAllCategories } from "features/categoriesSlice";

import { FormCreator } from "components";
import { portfolioSchema } from "./portfolioSchema";
import { formikValidation } from "helpers/formikValidation";
import { setAllPortfoliosWithoutPage } from "features/portfolioSlice";

export const CreateEditPortfolio = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [showHandWriteService, setShowHandWriteService] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  const editPortfolioId = useSelector((state) => state.portfolio.edit._id);
  const categories = useSelector((state) => state.categories.allCategories);
  const serviceTypes = useSelector((state) => state.serviceType.allServiceType);
  const allPortfoliosWithoutPage = useSelector(
    (state) => state.portfolio.allPortfoliosWithoutPage
  );
  const editPortfolioData = useSelector((state) => state.portfolio.edit.images);
  const editContent = useSelector((state) => state.portfolio.edit);

  const [createNewPortfolio, editPortfolio] = useActionHelper({
    createNewItemApiCall: portfolioApi.createNewPortfolio,
    updateItemApiCall: portfolioApi.updatePortfolio,
    editId: editPortfolioId,
  });

  const apiCall = location.pathname.includes("create")
    ? createNewPortfolio
    : editPortfolio;

  const [initialValues, validation] = formikValidation(
    portfolioSchema,
    showHandWriteService
  );

  useEffect(() => {
    const fetchBlog = async () => {
      const { data } = await categoryApi.allCategorysAdmin(0, 50);
      return data;
    };

    fetchBlog().then((res) => {
      dispatch(setAllCategories(res.categories));
    });
  }, []);

  useEffect(() => {
    dispatch(getServiceType());
  }, []);

  useEffect(() => {
    if (editPortfolioData) {
      setImageArray(editPortfolioData);
    } else {
      setImageArray([]);
    }
  }, [editPortfolioData]);

  useEffect(() => {
    const fetchPortfolio = async () => {
      const { data } = await portfolioApi.allPortfolioAdmin();
      return data;
    };

    fetchPortfolio().then((res) => {
      dispatch(setAllPortfoliosWithoutPage(res));
    });
  }, []);

  return (
    <FormCreator
      relatedOptions={allPortfoliosWithoutPage}
      pageName="portfolio"
      apiCall={apiCall}
      options={categories}
      serviceOptions={serviceTypes}
      editId={editPortfolioId}
      schema={portfolioSchema}
      editContent={editContent}
      showHandWriteService={showHandWriteService}
      setShowHandWriteService={setShowHandWriteService}
      initialValues={initialValues}
      validation={validation}
      setImageArray={setImageArray}
      imageArray={imageArray}
    />
  );
};
