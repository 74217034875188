import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "features/auth";

//MUI
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { MailOutlineTwoTone, LockTwoTone } from "@mui/icons-material";

//Styles
import "react-toastify/dist/ReactToastify.css";

//Images
import { ReactComponent as Logo } from "../assets/icon.svg";

export default function LivePreviewExample() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [rem, setRem] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate()

  const submit = (e) => {
    e.preventDefault();
    history('/home/technologies')
    dispatch(login({ email, password: pass, rem }));
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center"
                    >
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <Box
                          component="form"
                          onSubmit={submit}
                          className="py-4 display-flex"
                        >
                          <div className="text-center mb-5">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Login
                            </h1>
                          </div>
                          <div className="w-80">
                            <div className="mb-4">
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Email address"
                                type="email"
                                value={email}
                                autoComplete="true"
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                  required: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoTone />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Password"
                                type="password"
                                autoComplete="true"
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                                InputProps={{
                                  required: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockTwoTone />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center font-size-md">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rem}
                                    onChange={(e) => setRem(!rem)}
                                    value="checked1"
                                    color="primary"
                                  />
                                }
                                label="Remember me"
                              />
                            </div>
                            <div className="text-center py-4">
                              <Button
                                type="submit"
                                className="btn-login font-weight-bold w-30 my-2"
                              >
                                Sign in
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100  min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            style={{
                              top: 0,
                              left: 0,
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              backgroundColor: "#d6244b",
                            }}
                          />
                          <div className="bg-composed-wrapper--bg" />
                          <div className="bg-composed-wrapper--b" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">
                              <Logo />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
