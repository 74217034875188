import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { categoryApi } from "service/categories";
import { setAllCategories, setEditCategoryId } from "features/categoriesSlice";
import { CreateEditCategories } from "./CreateEditCaregories";
import MultiSelect from "components/MultiSelect/MultiSelect";
import ItemsList from "components/ItemsList/ItemsList";
import { deleteCategory } from "actions/categoriesAction";

import style from "./Categories.module.scss";

const Categories = ({ setFilteredType }) => {
  const dispatch = useDispatch();
  const [isViewMode, setIsViewMode] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [names, setNames] = useState([]);
  const categories = useSelector((state) => state.categories.allCategories);

  const createHandler = () => {
    setIsCreateEdit(true);
    setIsEdit(false);
    dispatch(setEditCategoryId(""));
  };

  const viewHandler = () => {
    setIsViewMode(true);
  };

  const closeHandel = () => {
    setIsViewMode(false);
  };

  const handleEdit = (data) => {
    setIsCreateEdit(true);
    setIsEdit(true);
    dispatch(setEditCategoryId(data));
  };

  useEffect(() => {
    const fetchBlog = async () => {
      const { data } = await categoryApi.allCategorysAdmin(0, 50);
      return data;
    };

    fetchBlog().then((res) => {
      dispatch(setAllCategories(res.categories));
    });
  }, []);

  useEffect(() => {
    setNames([]);
    categories?.forEach((category) => {
      const newCategory = {
        title: category.title,
        _id: category._id,
      };
      setNames((prev) => [...prev, newCategory]);
    });
  }, [categories]);

  return (
    <>
      {isViewMode && (
        <ItemsList
          closeHandel={closeHandel}
          handleDelete={deleteCategory}
          handleEdit={handleEdit}
          categories={categories}
          createHandler={createHandler}
        />
      )}

      <div className={style.category_wrapper}>
        <MultiSelect names={names} setFilteredType={setFilteredType} />

        <div className={style.buttons_wrapper}>
          <div className={style.create_button} onClick={viewHandler}>
            View Categories
          </div>
        </div>
      </div>
      <div className={style.create_edit_categories}>
        {isCreateEdit && (
          <CreateEditCategories
            isEdit={isEdit}
            setIsCreateEdit={setIsCreateEdit}
          />
        )}
      </div>
    </>
  );
};

export default Categories;
