import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "features/auth";
import headerReducer from "features/headerSlice";
import sidebarReducer from "features/sidebarSlice";
import mainReducer from "features/mainSlice";
import footerReducer from "features/footerSlice";
import pageTitleReducer from "features/pageTitleSlice";
import pageSlice from "features/pageSlice";
import blogSlice from "features/blogSlice";
import careerSlice from "features/careerSlice";
import serviceSlice from "features/serviceSlice";
import portfolioSlice from "features/portfolioSlice";
import testimonialsReducer from "features/testimonialsSlice";
import projectsReducer from "features/projectsSlice";
import aboutItemReducer from "features/aboutItemSlice";
import teamDetailsSlice from "features/teamDetailsSlice";
import teamMembersSlice from "features/teamMembersSlice";
import categoriesSlice from "features/categoriesSlice";
import serviceTypeSlice from "features/serviceTypeSlice";
import contactsSlice from "features/contactsSlice";
import recognitionsSlice from "features/recognitionsSlice";
import technologiesSlice from "features/technologiesSlice";
import aboutSlice from "features/aboutSlice";
import socialLinksSlice from "features/socialLinksSlice";
import formSlice from "features/formSlice";

export const store = configureStore({
  reducer: {
    auth: adminReducer,
    sidebar: sidebarReducer,
    header: headerReducer,
    main: mainReducer,
    footer: footerReducer,
    page: pageTitleReducer,
    pages: pageSlice,
    blog: blogSlice,
    career: careerSlice,
    service: serviceSlice,
    portfolio: portfolioSlice,
    testimonials: testimonialsReducer,
    projects: projectsReducer,
    aboutItem: aboutItemReducer,
    about: aboutSlice,
    categories: categoriesSlice,
    serviceType: serviceTypeSlice,
    teamDetails: teamDetailsSlice,
    teamMembers: teamMembersSlice,
    contacts: contactsSlice,
    recognitions: recognitionsSlice,
    technologies: technologiesSlice,
    socialLinks: socialLinksSlice,
    formCategories: formSlice,
  },
});
