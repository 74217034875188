import React from "react";
import { useSelector } from "react-redux";

import { useActionHelper } from "hooks/useActionHelper";

import { FormCreator } from "components";
import { createCategory, updateCategory } from "actions/categoriesAction";
import { categoriesSchema } from "./categoriesSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditCategories = ({ isEdit, setIsCreateEdit }) => {
  const editCategoriesId = useSelector(state => state.categories.edit._id);
  const editContent = useSelector((state) => state.categories.edit);

  const [createNewCategories, editCategories] = useActionHelper({
    createNewItemApiCall: createCategory,
    updateItemApiCall: updateCategory,
    editId: editCategoriesId,
    navigateTo: "categories",
    isEdit: true,
    setIsEdit: setIsCreateEdit,
  });

  const apiCall = !isEdit ? createNewCategories : editCategories;

  const [initialValues, validation] = formikValidation(categoriesSchema);

  return (
    <FormCreator
      pageName="categories"
      apiCall={apiCall}
      isFormData={false}
      showClose={true}
      setIsEdit={setIsCreateEdit}
      schema={categoriesSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
