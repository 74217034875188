import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const portfolioSchema = [
  {
    id: "name",
    name: "name",
    label: CONSTANTS.NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_NAME,
    backend_field: "name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "CKEditor",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_title",
    name: "meta_title",
    label: CONSTANTS.META_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_META_TITLE,
    backend_field: "meta_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "slug",
    name: "slug",
    label: CONSTANTS.SLUG,
    type: "text",
    placeholder: CONSTANTS.ENTER_SLUG,
    backend_field: "slug",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_description",
    name: "meta_description",
    label: CONSTANTS.META_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_META_DESCRIPTION,
    backend_field: "meta_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "client",
    name: "client",
    label: CONSTANTS.CLIENT,
    type: "text",
    placeholder: CONSTANTS.ENTER_CLIENT_NAME,
    backend_field: "client",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "industry",
    name: "industry",
    label: CONSTANTS.INDUSTRY,
    type: "text",
    placeholder: CONSTANTS.ENTER_CLIENT_INDUSTRY,
    backend_field: "industry",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "app_store",
    name: "app_store",
    label: CONSTANTS.APP_STORE,
    type: "text",
    placeholder: CONSTANTS.ENTER_CLIENT_APP_STORE,
    backend_field: "app_store",
    validation: Yup.string(),
  },
  {
    id: "google_play",
    name: "google_play",
    label: CONSTANTS.GOOGLE_PLAY,
    type: "text",
    placeholder: CONSTANTS.ENTER_CLIENT_GOOGLE_PLAY,
    backend_field: "google_play",
    validation: Yup.string(),
  },
  {
    id: "website",
    name: "website",
    label: CONSTANTS.WEBSITE,
    type: "text",
    placeholder: CONSTANTS.ENTER_CLIENT_WEBSITE,
    backend_field: "website",
    validation: Yup.string(),
  },
  {
    id: "categories",
    name: "categories",
    label: CONSTANTS.CATEGORIES,
    type: "selectArray",
    placeholder: CONSTANTS.ENTER_CATEGORIES,
    backend_field: "categories",
    validation: Yup.array().min(1, CONSTANTS.REQUIRED).of(Yup.string().required(CONSTANTS.REQUIRED)),
    valid: "text"
  },
  {
    id: "services",
    name: "services",
    label: CONSTANTS.SERVICES,
    type: "secondSelect",
    placeholder: CONSTANTS.ENTER_SERVICES,
    backend_field: "services",
    validation: Yup.array().min(1, CONSTANTS.REQUIRED).of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
  {
    id: "other_services",
    name: "other_services",
    label: CONSTANTS.OTHER_SERVICES,
    type: "other_services",
    placeholder: CONSTANTS.ENTER_OTHER_SERVICES,
    backend_field: "other_services",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "related_projects",
    name: "related_projects",
    label: CONSTANTS.RELATED_PROJECTS,
    type: "projectArray",
    placeholder: CONSTANTS.ENTER_RELATED_PROJECTS,
    backend_field: "related_projects",
    valid: "text"
  },
  {
    id: "image_information",
    name: "image_information",
    label: CONSTANTS.IMAGE_INFORMATION,
    type: "text",
    placeholder: CONSTANTS.ENTER_TEXT_ALONGSIDE_IMAGES,
    backend_field: "image_information",
    validation: Yup.string(),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "images",
    name: "images",
    label: CONSTANTS.IMAGES,
    type: "imageArray",
    placeholder: CONSTANTS.ENTER_IMAGES,
    backend_field: "images",
    validation: Yup.array().of(Yup.string()),
  },
];
