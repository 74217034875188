import moment from 'moment';

export const sortByDate = (data) => {
  const sorted = data
    .map((v) => {
      return { ...v, createdAt: moment(v.createdAt).toDate() };
    })
    .sort((first, second) => second.createdAt - first.createdAt);

  return sorted;
};
