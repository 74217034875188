import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { blogApi } from "service/blog";

import { FormCreator } from "components";
import { blogSchema } from "./blogSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditBlog = () => {
  const location = useLocation();

  const editBlogId = useSelector(state => state.blog.edit._id);
  const blogs = useSelector(state => state.blog.allBlogs);
  const editContent = useSelector((state) => state.blog.edit);

  const [createNewBlog, editBlog] = useActionHelper({
    createNewItemApiCall: blogApi.createNewBlog,
    updateItemApiCall: blogApi.updateBlog,
    editId: editBlogId
  });

  const apiCall = location.pathname.includes("create") ? createNewBlog : editBlog;

  const [initialValues, validation] = formikValidation(blogSchema);

  return (
    <FormCreator
      relatedOptions={blogs}
      pageName="blog"
      apiCall={apiCall}
      editId={editBlogId}
      schema={blogSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
