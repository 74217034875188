import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSidebarToggleMobile } from "features/sidebarSlice";
import HeaderSearch from "layoutComponents/HeaderSearch";
import HeaderUserbox from "layoutComponents/HeaderUserbox";

import clsx from "clsx";

const Header = () => {
  const { headerShadow, headerBgTransparent } = useSelector(
    (state) => state.header
  );
  const { sidebarToggleMobile } = useSelector((state) => state.sidebar);

  const dispatch = useDispatch();

  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  return (
    <>
      <div
        className={clsx("app-header", {
          "app-header--shadow": headerShadow,
          "app-header--opacity-bg": headerBgTransparent,
        })}
      >
        <div
          style={{
            width: "100%",
          }}
          className="app-header--pane w100"
        >
          <button
            className={clsx(
              "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
              { "is-active": sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <HeaderSearch />
          <div
            style={{
              marginLeft: "auto",
            }}
            className="text-center d-flex"
          >
            <HeaderUserbox />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
