import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as DOMPurify from 'dompurify';

import { Button } from "@mui/material";

import Image from "components/Image";

const ViewBlog = () => {
  const navigate = useNavigate();

  const data = useSelector((state) => state?.blog?.edit);

  const clean = DOMPurify.sanitize(data?.description, { USE_PROFILES: { html: true } });

  const closeViewPage = () => {
    navigate(-1);
  };

  return (
    <div className="view_field">
      <div className="view_cancel_button">
        <Button onClick={closeViewPage}>Back</Button>
      </div>
      <div className="view_contant">
        <div className="view_title">
          <h2>Blog Details</h2>
        </div>
        <div className="view_container">
          <div className="view_subtitle">Image</div>
          <div className="view_image_filed">
            <Image image={data?.image} />
          </div>
          <div className="view_subtitle">Title</div>
          <div className="view_text">{data?.title}</div>
          <div className="view_subtitle">Description</div>
          <div className="content" dangerouslySetInnerHTML={{__html: clean || ""}}></div>
          <div className="view_subtitle">Short Description</div>
          <div className="view_text">{data?.short_description}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewBlog;
