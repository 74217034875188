import React from "react";
import { useSelector } from "react-redux";

import { useActionHelper } from "hooks/useActionHelper";

import { FormCreator } from "components";
import { createServiceType, updateServiceType } from "actions/serviceTypeAction";
import { serviceTypeSchema } from "./serviceTypeSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditServiceType = ({ isEdit, setIsCreateEdit }) => {
  const editServiceTypeId = useSelector((state) => state.serviceType.edit._id);
  const editContent = useSelector((state) => state.serviceType.edit);

  const [createNewServiceType, editServiceType] = useActionHelper({
    createNewItemApiCall: createServiceType,
    updateItemApiCall: updateServiceType,
    editId: editServiceTypeId,
    navigateTo: "serviceType",
    isEdit: true,
    setIsEdit: setIsCreateEdit,
  });

  const apiCall = !isEdit ? createNewServiceType : editServiceType;

  const [initialValues, validation] = formikValidation(serviceTypeSchema);

  return (
    <FormCreator
      pageName="serviceType"
      apiCall={apiCall}
      isFormData={false}
      showClose={true}
      setIsEdit={setIsCreateEdit}
      schema={serviceTypeSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  );
};
