import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faList } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faContactBook } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBlog } from "@fortawesome/free-solid-svg-icons";

export const Menu = [
  {
    id: "1",
    name: "Home Page",
    path: "/home",
    icon: <FontAwesomeIcon icon={faHome} />,
    menu: [
      {
        id: "technologies",
        name: "Technologies",
        path: "/home/technologies",
      },
      {
        id: "testimonials",
        name: "Testimonials",
        path: "/home/testimonials",
      },
      {
        id: "recognition",
        name: "Recognitions",
        path: "/home/recognitions",
      },
    ],
  },
  {
    id: "2",
    name: "About",
    path: "/about",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    menu: [
      {
        id: "nextstack",
        name: "NextStack",
        path: "/about/nextstack",
      },
      {
        id: "our_values",
        name: "Our values",
        path: "/about/our-values",
      },
    ],
  },
  {
    id: "3",
    name: "Projects",
    path: "/portfolio",
    icon: <FontAwesomeIcon icon={faBriefcase} />,
  },
  {
    id: "4",
    name: "Our Services",
    path: "/service",
    icon: <FontAwesomeIcon icon={faContactBook} />,
  },
  {
    id: "5",
    name: "Blog",
    path: "/blog",
    icon: <FontAwesomeIcon icon={faBlog} />,
  },
  {
    id: "6",
    name: "Team",
    path: "/team",
    icon: <FontAwesomeIcon icon={faBriefcase} />,
    menu: [
      {
        id: "our_team",
        name: "Our Team",
        path: "/team/our-team",
      },
      {
        id: "content",
        name: "Content",
        path: "/team/team-details",
      },
    ],
  },
  {
    id: "7",
    name: "Career",
    path: "/career",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
  },
  {
    id: "8",
    name: "Contacts",
    path: "/contacts",
    icon: <FontAwesomeIcon icon={faContactBook} />,
    menu: [
      {
        id: "contact_details",
        name: "Contact Details",
        path: "/contacts/details",
      },
      {
        id: "social_links",
        name: "Social Links",
        path: "/contacts/social-links",
      },
      {
        id: "form-category",
        name: "Subjects",
        path: "/contacts/form-category",
      },
    ],
  },
  {
    id: "9",
    name: "Pages",
    path: "/pages",
    icon: <FontAwesomeIcon icon={faList} />,
  },
];
