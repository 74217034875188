import React from "react";
import { useActionHelper } from "hooks/useActionHelper";

import { FormCreator } from "components";
import { changePasswordSchema } from "./ChangePasswordSchema";
import { formikValidation } from "helpers/formikValidation";
import { authApi } from "service/authApi";
import { CONSTANTS } from "constants/general";

export const ChangePassword = () => {

  const [changePassword] = useActionHelper({
    createNewItemApiCall: authApi.changePassword,
    customNotify: CONSTANTS.PASSWORD_CHANGE_SUCCESS,
  });

  const apiCall = changePassword;

  const [initialValues, validation] = formikValidation(changePasswordSchema);

  return (
    <FormCreator
      pageName="ChangePassword"
      apiCall={apiCall}
      isFormData={false}
      schema={changePasswordSchema}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
