import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { recognitionApi } from "service/recognition";

import { FormCreator } from "components";
import { recognitionsSchema } from "./recognitionsSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditRecognitions = () => {
  const location = useLocation();

  const editRecognitionId = useSelector(state => state.recognitions.edit._id);
  const editContent = useSelector((state) => state.recognitions.edit);

  const [createNewTechnology, editTechnology] = useActionHelper({
    createNewItemApiCall: recognitionApi.createNewRecognition,
    updateItemApiCall: recognitionApi.updateRecognition,
    editId: editRecognitionId,
  });

  const apiCall = location.pathname.includes("create") ? createNewTechnology : editTechnology;

  const [initialValues, validation] = formikValidation(recognitionsSchema);

  return (
    <FormCreator
      pageName="recognitions"
      apiCall={apiCall}
      schema={recognitionsSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
