import instance from "./baseRequest";

export const technologyApi = {
  createNewTechnology: async (data) => {
    await instance.post('/admin/technologies/store', data);
  },
  updateTechnology: async (id, data) => {
    return await instance.put(`/admin/technologies/update/${id}`, data);
  },
  deleteTechnology: async (id) => {
    return await instance.delete(`/admin/technologies/delete/${id}`);
  },
  getTechnologyById: async (id) => {
    return await instance.get(`/v1/technologies/${id}`);
  },
  allTechnologyAdmin: async (page, limit, search) => {
    return await instance.get('/admin/technologies', {
      params: {
        page,
        limit,
        search,
      }
    });
  },
  getAllTechnology: async (page, limit) => {
    return await instance.get(`/v1/technologies`, {
      params: {
        page,
        limit
      }
    });
  },
}
