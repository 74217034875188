import React, {useState} from 'react';

import { FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';

import {useTheme} from "@mui/material/styles";
import style from "pages/Portfolio/Categories.module.scss";

const MultiSelect = ({ names, setFilteredType }) => {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;
    setFilteredType(value);
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div className={style.select}>
      <FormControl sx={{m: 1, width: 300, mt: 3}}>
        <Select
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput/>}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Placeholder</em>;
            }
            if(selected.length > 3) {
              selected.splice(3,1);
            }

            return selected.map(sel => sel.title).join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{'aria-label': 'Without label'}}
        >
          <MenuItem disabled value="">
            <em>Placeholder</em>
          </MenuItem>
          {names.map((name) => (
            <MenuItem
              key={name._id}
              value={name}
              style={getStyles(name.title, personName, theme)}
            >
              {name.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelect;
