import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: '',
  allAboutItems: [],
  count: 0,
};

export const aboutItemSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    setAllAboutItems(state, { payload }) {
      state.allAboutItems = payload.aboutItems;
      state.count = payload.count;
    },
    setEditAboutItemId(state, payload) {
      state.edit = payload.payload;
    },
    clearEditAboutItem(state) {
      state.edit = '';
    },
  },
});

export const {
	setAllAboutItems,
	setEditAboutItemId,
  clearEditAboutItem,
} = aboutItemSlice.actions;

export default aboutItemSlice.reducer;
