import instance from "./baseRequest";

export const teamMembersApi = {
  createNewTeamMember: async (data) => {
    await instance.post('/admin/teams/store', data);
  },
  updateTeamMember: async (id, data) => {
    return await instance.put(`/admin/teams/update/${id}`, data);
  },
  deleteTeamMember: async (id) => {
    return await instance.delete(`/admin/teams/delete/${id}`);
  },
  allTeamMembersAdmin: async (page, limit, search=null) => {
    return await instance.get('/admin/teams', {
      params: {
        page,
        limit,
        search
      }
    });
  },
  getAllTeamMembers: async (page, limit) => {
    return await instance.get(`/v1/teams`, {
      params: {
        page,
        limit
      }
    });
  },
}