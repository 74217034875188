import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  footerFixed: false,
  footerShadow: false,
  footerBgTransparent: true,
};

export const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    setFooterFixed(state, footerFixed) {
      state.footerFixed = footerFixed.payload;
    },
    setFooterShadow(state, footerShadow) {
      state.footerShadow = footerShadow.payload;
    },
    setFooterBgTransparent(state, footerBgTransparent) {
      state.footerBgTransparent = footerBgTransparent.payload;
    },
  },
});

export const { setFooterFixed, setFooterShadow, setFooterBgTransparent } =
  footerSlice.actions;

export default footerSlice.reducer;
