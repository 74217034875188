import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contentBackground: "",
  themeConfiguratorToggle: false,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setContentBackground(state, contentBackground) {
      state.contentBackground = contentBackground.payload;
    },
    setThemeConfiguratorToggle(state, themeConfiguratorToggle) {
      state.themeConfiguratorToggle = themeConfiguratorToggle.payload;
    },
  },
});

export const { setContentBackground, setThemeConfiguratorToggle } = mainSlice.actions;

export default mainSlice.reducer;
