import { createSlice } from "@reduxjs/toolkit";
import { getRecognitions, deleteRecognition } from "actions/recognitionsAction";

const initialState = {
  edit: "",
  recognitions: [],
  count: 0,
  status: null,
};

export const recognitionsSlice = createSlice({
  name: "recognitions",
  initialState,
  reducers: {
    setEditRecognitionsId(state, payload) {
      state.edit = payload.payload;
    },
  },
  extraReducers: {
    [getRecognitions.pending]: (state) => {
      state.status = "loading";
    },
    [getRecognitions.fulfilled]: (state, { payload }) => {
      state.recognitions = payload.recognitions;
      state.count = payload.count;
      state.status = "solved";
    },
    [getRecognitions.rejected]: (state) => {
      state.status = "reject";
    },
    [deleteRecognition.rejected]: (state) => {
      state.status = "reject";
    },
  },
});

export const { setEditRecognitionsId } = recognitionsSlice.actions;

export default recognitionsSlice.reducer;