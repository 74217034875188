import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as DOMPurify from 'dompurify';

import { Button } from "@mui/material";

import { getServiceType } from "actions/serviceTypeAction";
import Image from "components/Image";
import { CONSTANTS } from "constants/general";

const ViewPortfolio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.portfolio?.edit);
  const categories = useSelector((state) => state?.categories?.allCategories);
  const services = useSelector((state) => state?.serviceType?.allServiceType);

  const cleanDescription = DOMPurify.sanitize(data?.description, { USE_PROFILES: { html: true } });

  const handleCloseViewPage = () => {
    navigate(-1);
  };

  const relatedCategories = categories?.filter((category) =>
    data?.categories?.includes(category?._id)
  );

  const relatedServices = services?.filter((service) =>
    data?.services?.includes(service?._id)
  );

  useEffect(() => {
    dispatch(getServiceType());
  }, []);

  return (
    <div className="view_field">
      <div className="view_cancel_button">
        <Button onClick={handleCloseViewPage}>{CONSTANTS.BACK}</Button>
      </div>
      <div className="view_contant">
        <div className="view_title">
          <h2>{CONSTANTS.PORTFOLIO_DETAILS}</h2>
        </div>
        <div className="view_container">
          <div className="view_subtitle">{CONSTANTS.IMAGE}</div>
          <div className="view_image_filed">
            <Image image={data?.image} />
          </div>
          <div className="view_subtitle">{CONSTANTS.NAME}</div>
          <div className="view_text">{data?.name}</div>
          <div className="view_subtitle">{CONSTANTS.DESCRIPTION}</div>
          <div className="view_text" dangerouslySetInnerHTML={{__html: cleanDescription || ""}}></div>
          <div className="view_subtitle">{CONSTANTS.CLIENT}</div>
          <div className="view_text">{data?.client}</div>
          <div className="view_subtitle">{CONSTANTS.INDUSTRY}</div>
          <div className="view_text">{data?.industry}</div>
          <div className="view_subtitle">{CONSTANTS.APP_STORE}</div>
          <div className="view_text">{data?.app_store}</div>
          <div className="view_subtitle">{CONSTANTS.GOOGLE_PLAY}</div>
          <div className="view_text">{data?.google_play}</div>
          <div className="view_subtitle">{CONSTANTS.CATEGORIES}</div>
          {relatedCategories.map((category) => (
            <div className="view_text">{category?.title}</div>
          ))}
          <div className="view_subtitle">{CONSTANTS.SERVICES}</div>
          {relatedServices.map((service) => (
            <div className="view_text">{service?.title}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewPortfolio;
