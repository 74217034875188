import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BUTTON_TYPES } from "./constants";

const useStyles = makeStyles(({ type }) => {
  switch (type) {
    case BUTTON_TYPES.primary:
      return {
        root: {},
      };
    case BUTTON_TYPES.secondary:
      return {
        root: {},
      };
    default:
      return {
        root: {},
      };
  }
});

export const Button = (props) => {
  const classes = useStyles(props.type);
  return (
    <MuiButton
      className={classes.root + props.customClasses}
      onClick={props.click}
    >
      {props.children}
    </MuiButton>
  );
};
