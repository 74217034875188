import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: "",
  allBlogs: [],
  count: 0,
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setAllBlogs(state, { payload }) {
      state.allBlogs = payload.blogs;
      state.count = payload.count;
    },
    setEditBlogId(state, payload) {
      state.edit = payload.payload;
    },

    clearEditBlog(state) {
      state.edit = "";
    },
  },
});

export const { setAllBlogs, setEditBlogId, clearEditBlog } = blogSlice.actions;

export default blogSlice.reducer;
