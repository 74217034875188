import { createAsyncThunk } from "@reduxjs/toolkit";

import { categoryApi } from "service/categories";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";

export const getAllCategories = createAsyncThunk(
  "categories",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const res = await categoryApi.allCategorysAdmin(page, limit);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories",
  async ({id}, { rejectWithValue, dispatch }) => {
    try {
      const res = await categoryApi.deleteCategory(id);
      Notify(CONSTANTS.SUCCESS, CONSTANTS.REMOVED_CATEGORY);
      dispatch(getAllCategories({ page: 0, limit: 50 }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "/categories",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await categoryApi.createNewCategory(data);
      Notify(CONSTANTS.SUCCESS, CONSTANTS.ADDED_CATEGORY);
      dispatch(getAllCategories({ page: 0, limit: 50 }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "/categories",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await categoryApi.updateCategory(id, data);
      Notify(CONSTANTS.SUCCESS, CONSTANTS.UPDATED_CATEGORY);
      dispatch(getAllCategories({ page: 0, limit: 50 }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
