import instance from "./baseRequest";

export const socialLinkApi = {
  createNewSocialLink: async (data) => {
    await instance.post('/admin/social-links/store', data);
  },
  updateSocialLink: async (id, data) => {
    return await instance.put(`/admin/social-links/update/${id}`, data);
  },
  deleteSocialLink: async (id) => {
    return await instance.delete(`/admin/social-links/delete/${id}`);
  },
  getSocialLinkById: async (id) => {
    return await instance.get(`/v1/social-links/${id}`);
  },
  allSocialLinkAdmin: async (page, limit, search) => {
    return await instance.get('/admin/social-links', {
      params: {
        page,
        limit,
        search,
      }
    });
  },
  allSocialLinkV1: async (page, limit) => {
    return await instance.get('/v1/social-links', {
      params: {
        page,
        limit
      }
    });
  },
  getAllSocialLink: async (page, limit) => {
    return await instance.get(`/v1/technologies`, {
      params: {
        page,
        limit
      }
    });
  },
}
