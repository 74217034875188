import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";

import * as Yup from "yup";
import { useFormik } from "formik";

import StyledCropEasy from "../crop/CropEasy";

import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { UploadFile, Save, Close, Delete } from "@mui/icons-material";

import { PAGE_NAME_OBJ, FORM_ITEM_TYPES } from "constants/pageName";
import ImageSection from "./ImageSection/ImageSection";

import style from "./formCreator.module.scss";
import { CONSTANTS, TITLE, DESCRIPTION } from "constants/general";
import { formErrorHelper } from "helpers/formikValidation";
import { submitData } from "helpers/formikDataCollect";
import { MainCheckBox } from "components/MainCheckbox";
import { getTodayHelper, momentHelper } from "helpers/momentHelper";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const FormCreator = ({
  apiCall,
  pageName,
  options,
  serviceOptions,
  relatedOptions,
  schema,
  isFormData = true,
  setIsEdit = null,
  showClose = false,
  isArray = false,
  isCropper = false,
  editId = null,
  editContent,
  onFileCropperChange = () => {},
  imageSrc = null,
  setImageSrc = () => {},
  checkCropperMode = () => {},
  initialValues = {},
  validation = {},
  showHandWriteService,
  setShowHandWriteService = () => {},
  setImageArray,
  imageArray,
  imageArraySize,
  validSchema = null,
  isCustomChecked,
  setIsCustomChecked = () => {},
  customKey = "",
  notCustomKey = "",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showImage, setShowImage] = useState("");
  const [showIcon, setShowIcon] = useState("");
  const [newImageArray, setNewImageArray] = useState([]);
  const [oldImageArray, setOldImageArray] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [location, setLocation] = useState(false);
  const [contact, setContact] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);

  const validationSchema =
    validSchema ||
    Yup.object().shape({
      ...validation,
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      values.is_local_project = !values.is_custom_project;
      submitData(
        values,
        dispatch,
        checkCropperMode,
        showImage,
        editContent,
        isFormData,
        apiCall,
        setIsEdit,
        pageName
      );
    },
  });

  const handleChangeSelectBoxValue = (e, elem) => {
    if (elem.valid !== CONSTANTS.VALID_TEXT) {
      formik.setFieldValue(elem.name, [e.target.value]);
    } else {
      formik.setFieldValue(elem.name, e.target.value || null);
    }
  };

  const handleAddFormFieldArrayItem = (key, type) => {
    if (type === FORM_ITEM_TYPES.careerArray) {
      setDescriptionArray((state) => [
        ...formik.values[key],
        { title: "", description: "" },
      ]);
    } else if (type === FORM_ITEM_TYPES.imageArray) {
      setImageArray((state) => [...state, ""]);
    } else {
      formik.setFieldValue(key, [...formik.values[key], ""]);
    }
  };

  const handleDeleteFormFieldArrayItem = (key, index) => {
    if (imageArray) {
      setImageArray((state) => state.filter((val, ind) => ind !== index));
    }
    if (descriptionArray) {
      setDescriptionArray((state) => state.filter((val, ind) => ind !== index));
    }
    const newArray = [...formik.values[key]];
    newArray.splice(index, 1);
    formik.setFieldValue(key, [...newArray]);
  };

  const handleAddFile = (event, index) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    handleUploadFiles(chosenFiles, index);
  };

  const handleUploadFiles = (files, index) => {
    const uploaded = [...imageArray];
    files.some((file) => {
      if (uploaded[index]) {
        uploaded[index] = file;
      } else if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded[index] = file;
      }
    });
    setImageArray(uploaded);
  };

  const backButton = () => {
    navigate(-1);
  };

  const handleSetLocation = (elem) => {
    if (elem.type === FORM_ITEM_TYPES.textLocation)
      formik.setFieldValue(elem.name, CONSTANTS.YEREVAN_KIMITAS);
    if (elem.type === FORM_ITEM_TYPES.textContactInfo)
      formik.setFieldValue(elem.name, CONSTANTS.CELL_PHONE_NUMBER);
  };

  useEffect(() => {
    setNewImageArray([]);
    setOldImageArray([]);
    imageArray?.forEach((image) => {
      if (typeof image === "string") {
        if (!!image) {
          setOldImageArray((state) => [...state, image]);
        }
      } else {
        setNewImageArray((state) => [...state, image]);
      }
    });
  }, [imageArray]);

  useEffect(() => {
    if (
      pageName === PAGE_NAME_OBJ.about ||
      pageName === PAGE_NAME_OBJ.teamDetails ||
      pageName === PAGE_NAME_OBJ.portfolio
    ) {
      formik.setFieldValue("images", newImageArray);
      formik.setFieldValue("oldImages", oldImageArray);
    }
  }, [newImageArray, oldImageArray]);

  useEffect(() => {
    if (pageName === PAGE_NAME_OBJ.career) {
      setDescriptionArray(
        options?.description || [{ title: "", description: "" }]
      );
    }
  }, []);

  useEffect(() => {
    if (pageName === PAGE_NAME_OBJ.career) {
      formik.setFieldValue("description", descriptionArray);
    }
  }, [descriptionArray]);

  useEffect(() => {
    if (editContent) {
      schema.forEach((elem) => {
        formik.setFieldValue(elem.name, editContent[elem.name]);
      });

      setShowHandWriteService(!!editContent?.other_services);
    }
  }, [editContent]);

  useEffect(() => {
    if (formik.values?.services?.[0] === CONSTANTS.OTHER_SERVICE) {
      setShowHandWriteService(true);
    }
  }, [formik.values]);

  useEffect(() => {
    setTimeout(() => {
      setEditorLoaded(true);
    }, 0);
  }, []);

  return (
    <div className={style.form_container}>
      <div className={style.form_back_btn_field}>
        {!showClose && (
          <Button className={style.form_back_btn} onClick={backButton}>
            Back
          </Button>
        )}
      </div>
      <form onSubmit={formik.handleSubmit} className={style.testimonials_form}>
        {showClose && (
          <div className={style.cancel_button}>
            <Button onClick={() => setIsEdit(false)}>
              <Close />
            </Button>
          </div>
        )}
        {schema.map((elem) => (
          <React.Fragment key={elem.backend_field}>
            {(elem.type === FORM_ITEM_TYPES.text ||
              elem.type === FORM_ITEM_TYPES.password ||
              elem.type === FORM_ITEM_TYPES.textarea) &&
              (!elem.isConditional || isCustomChecked) && (
                <div className={style.field_Container} key={elem.id}>
                  <TextField
                    fullWidth
                    id={elem.id}
                    name={elem.name}
                    label={elem.label}
                    variant="outlined"
                    type={elem.type}
                    multiline={elem.type === FORM_ITEM_TYPES.textarea}
                    minRows={4}
                    maxRows={10}
                    value={formik.values[elem.name]}
                    onChange={formik.handleChange}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  />
                </div>
              )}
            {elem.type === FORM_ITEM_TYPES.CKEditor && (
              <div className={style.field_Container} key={elem.id}>
                <div
                  className={`${style.editor_wrapper} ${
                    formik.touched[elem.name] &&
                    formik.errors[elem.name] &&
                    style.editor_wrapper_error
                  }`}
                >
                  <div className={style.editor_wrapper_description}>
                    {CONSTANTS.DESCRIPTION}
                  </div>
                  {editorLoaded && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={formik.values.description || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        formik.setFieldValue(elem.name, data);
                      }}
                    />
                  )}
                  {formik.errors[elem.name] && formik.touched[elem.name] && (
                    <div className={style.editor_error}>
                      {CONSTANTS.REQUIRED}
                    </div>
                  )}
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.textLocation && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.location}>
                  <TextField
                    fullWidth
                    id={elem.id}
                    name={elem.name}
                    label={elem.label}
                    variant="outlined"
                    value={formik.values[elem.name]}
                    onChange={formik.handleChange}
                    onFocus={() => setLocation(true)}
                    onBlur={(e) =>
                      setTimeout(() => {
                        setLocation(false);
                        formik.handleBlur(e);
                      }, 100)
                    }
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  />
                  <div
                    className={
                      location ? style.show_suggestion : style.hide_suggestion
                    }
                  >
                    <ul>
                      <li onClick={() => handleSetLocation(elem)}>
                        {CONSTANTS.YEREVAN_KIMITAS}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.textContactInfo && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.location}>
                  <TextField
                    fullWidth
                    id={elem.id}
                    name={elem.name}
                    label={elem.label}
                    variant="outlined"
                    value={formik.values[elem.name]}
                    onChange={formik.handleChange}
                    onFocus={() => setContact(true)}
                    onBlur={(e) =>
                      setTimeout(() => {
                        setContact(false);
                        formik.handleBlur(e);
                      }, 100)
                    }
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  />
                  <div
                    className={
                      contact ? style.show_suggestion : style.hide_suggestion
                    }
                  >
                    <ul>
                      <li onClick={() => handleSetLocation(elem)}>
                        {CONSTANTS.CELL_PHONE_NUMBER}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.date && (
              <div className={style.field_Container} key={elem.id}>
                <TextField
                  fullWidth
                  id={elem.id}
                  type={elem.type}
                  name={elem.name}
                  label={elem.label}
                  variant="outlined"
                  value={momentHelper(formik.values[elem.name])}
                  onChange={formik.handleChange}
                  inputProps={{
                    min: getTodayHelper(),
                  }}
                  error={formErrorHelper({
                    formik,
                    elementName: elem.name,
                    isBoolean: true,
                  })}
                  helperText={formErrorHelper({
                    formik,
                    elementName: elem.name,
                  })}
                />
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.file && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.image_wrapper}>
                  <label
                    className={`${style.testimonial_image} ${
                      formErrorHelper({
                        formik,
                        elementName: elem.name,
                        isBoolean: true,
                      }) && style.testimonial_image_error
                    }`}
                    htmlFor={elem.id}
                  >
                    {showImage && elem.id === "image" ? (
                      <img src={URL.createObjectURL(showImage)} alt="Avatar" />
                    ) : showIcon && elem.id === "icon" ? (
                      <img src={URL.createObjectURL(showIcon)} alt="Avatar" />
                    ) : !!editContent ? (
                      <img
                        src={`${image_url}${editContent[elem.backend_field]}`}
                        alt="Avatar"
                      />
                    ) : (
                      <UploadFile />
                    )}
                  </label>
                  <input
                    id={elem.id}
                    name={elem.name}
                    type={elem.type}
                    className={style.testimonials_image_input}
                    onChange={(event) => {
                      formik.setFieldValue(
                        elem.name,
                        event.currentTarget.files[0]
                      );

                      if (elem.id === "image")
                        setShowImage(event.target.files[0]);
                      if (isCropper) {
                        onFileCropperChange(event, setImageSrc);
                      }
                      if (elem.id === "icon")
                        setShowIcon(event.target.files[0]);
                    }}
                  />
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.selectOfArray && (
              <div className={style.field_Container} key={elem.id}>
                <FormControl
                  fullWidth
                  error={formErrorHelper({
                    formik,
                    elementName: elem.name,
                    isBoolean: true,
                  })}
                >
                  <InputLabel id={elem.id}>Categories</InputLabel>
                  <Select
                    multiple
                    displayEmpty
                    id={elem.id}
                    select
                    variant="outlined"
                    label={elem.label}
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                    renderValue={(selected) => {
                      return selected
                        .map(
                          (sel) => options.find((el) => el._id === sel)?.title
                        )
                        ?.join(", ");
                    }}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                  >
                    {options?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name || option.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formErrorHelper({ formik, elementName: elem.name })}
                  </FormHelperText>
                </FormControl>
              </div>
            )}
            {(elem.type === FORM_ITEM_TYPES.projectOfArray ||
              elem.type === FORM_ITEM_TYPES.blogOfArray) && (
              <div className={style.field_Container} key={elem.id}>
                <FormControl
                  fullWidth
                  error={formErrorHelper({
                    formik,
                    elementName: elem.name,
                    isBoolean: true,
                  })}
                >
                  <InputLabel id={elem.id}>
                    {(elem.type === FORM_ITEM_TYPES.projectOfArray &&
                      CONSTANTS.RELATED_PROJECTS) ||
                      (elem.type === FORM_ITEM_TYPES.blogOfArray &&
                        CONSTANTS.RELATED_BLOGS)}
                  </InputLabel>
                  <Select
                    multiple
                    displayEmpty
                    id={elem.id}
                    select
                    variant="outlined"
                    label={elem.label}
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                  >
                    {relatedOptions
                      ?.filter((elem) => elem._id !== editId)
                      ?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name || option.title}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {formErrorHelper({ formik, elementName: elem.name })}
                  </FormHelperText>
                </FormControl>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.select &&
              (!elem.isConditional || !isCustomChecked) && (
                <div className={style.field_Container} key={elem.id}>
                  <TextField
                    fullWidth
                    id={elem.id}
                    select
                    variant="outlined"
                    label={elem.label}
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {options?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name || option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}

            {elem.type === FORM_ITEM_TYPES.checkbox && (
              <div className={style.field_Container} key={elem.id}>
                <div className="checkbox_container">
                  <FormControlLabel
                    control={
                      <MainCheckBox
                        checked={formik.values[elem.name]}
                        onChange={(e) => {
                          setIsCustomChecked(!formik.values[elem.name]);
                          if (formik.values[elem.name]) {
                            formik.setFieldValue(notCustomKey, "");
                          } else {
                            formik.setFieldValue(customKey, "");
                          }
                          formik.setFieldValue(elem.name, e.target.checked);
                        }}
                        name={elem.name}
                      />
                    }
                    label={elem.label}
                  />
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.secondSelect &&
              !showHandWriteService && (
                <div className={style.field_Container} key={elem.id}>
                  <FormControl
                    fullWidth
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                  >
                    <InputLabel id={elem.id}>Services</InputLabel>
                    <Select
                      fullWidth
                      id={elem.id}
                      select
                      variant="outlined"
                      label={elem.label}
                      value={formik.values[elem.backend_field]}
                      onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                      error={formErrorHelper({
                        formik,
                        elementName: elem.name,
                        isBoolean: true,
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {serviceOptions?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name || option.title}
                        </MenuItem>
                      ))}
                      <MenuItem value={CONSTANTS.OTHER_SERVICE}>
                        <em>{CONSTANTS.OTHER_SERVICE}</em>
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {formErrorHelper({ formik, elementName: elem.name })}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            {elem.type === FORM_ITEM_TYPES.otherServices &&
              showHandWriteService && (
                <div className={style.field_Container} key={elem.id}>
                  <TextField
                    fullWidth
                    id={elem.id}
                    name={elem.name}
                    label={elem.label}
                    variant="outlined"
                    multiline={elem.type === FORM_ITEM_TYPES.textarea}
                    minRows={4}
                    maxRows={10}
                    value={formik.values[elem.name]}
                    onChange={formik.handleChange}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  />
                </div>
              )}
            {elem.type === FORM_ITEM_TYPES.array && isArray && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.array_wrapper}>
                  {formik.values?.[elem.backend_field]?.map((v, index) => (
                    <div className={style.array_field} key={index}>
                      <TextField
                        fullWidth
                        id={`${elem.id}${index}`}
                        name={`${elem.backend_field}[${index}]`}
                        label={`${elem.label} ${index + 1}`}
                        variant="outlined"
                        value={formik.values[elem.backend_field][index]}
                        className={style.array_input}
                        onChange={formik.handleChange}
                        error={formErrorHelper({
                          formik,
                          elementName: elem.name,
                          isBoolean: true,
                          type: FORM_ITEM_TYPES.array,
                          index,
                        })}
                        helperText={formErrorHelper({
                          formik,
                          elementName: elem.name,
                          type: FORM_ITEM_TYPES.array,
                          index,
                        })}
                      />
                      <Delete
                        onClick={() =>
                          handleDeleteFormFieldArrayItem(
                            elem.backend_field,
                            index
                          )
                        }
                      />
                    </div>
                  ))}
                  <Button
                    className={`${style.add_button} ${style.add_button_margin}`}
                    onClick={() =>
                      handleAddFormFieldArrayItem(elem.backend_field, elem.type)
                    }
                  >
                    Add {elem.label}
                  </Button>
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.careerArray && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.array_wrapper}>
                  {formik?.values?.[elem.backend_field]?.map((desc, index) => (
                    <div
                      className={`${style.field_Container} ${style.width_100}`}
                      key={desc._id}
                    >
                      <div className={`${style.editor_wrapper}`}>
                        <TextField
                          fullWidth
                          id={`${elem.id}${index}title`}
                          name={`${elem.backend_field}[${index}].title`}
                          label={`${elem.titleLabel} ${index + 1}`}
                          variant="outlined"
                          value={formik.values[elem.backend_field][index].title}
                          className={`${style.array_input} ${style.career_array}`}
                          onChange={formik.handleChange}
                          error={formErrorHelper({
                            formik,
                            elementName: elem.name,
                            isBoolean: true,
                            type: FORM_ITEM_TYPES.careerArray,
                            name: TITLE,
                            index: index,
                          })}
                          helperText={formErrorHelper({
                            formik,
                            elementName: elem.name,
                            type: FORM_ITEM_TYPES.careerArray,
                            name: TITLE,
                            index: index,
                          })}
                        />
                        <div className={style.editor_wrapper_description}>
                          {`${CONSTANTS.DESCRIPTION} ${index + 1}`}
                        </div>
                        {editorLoaded && (
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              formik?.values?.[elem?.backend_field]?.[index]
                                ?.description || ""
                            }
                            onChange={(event, editor) => {
                              const data = editor?.getData();
                              formik.setFieldValue(
                                `${DESCRIPTION}.${index}.${DESCRIPTION}`,
                                data
                              );
                            }}
                          />
                        )}
                        {formik.errors[elem.name]?.[index]?.description && (
                          <div className={style.editor_error}>
                            {CONSTANTS.REQUIRED}
                          </div>
                        )}
                      </div>
                      <Delete
                        onClick={() =>
                          handleDeleteFormFieldArrayItem(
                            elem.backend_field,
                            index
                          )
                        }
                      />
                    </div>
                  ))}
                  <Button
                    className={`${style.add_button} ${style.add_button_margin}`}
                    onClick={() =>
                      handleAddFormFieldArrayItem(elem.backend_field, elem.type)
                    }
                  >
                    {`${CONSTANTS.ADD_BUTTON} ${elem.label}`}
                  </Button>
                </div>
              </div>
            )}
            {elem.type === FORM_ITEM_TYPES.imageArray && (
              <div className={style.field_Container} key={elem.id}>
                <div className={style.array_wrapper}>
                  {(!imageArraySize || imageArraySize > imageArray.length) && (
                    <Button
                      className={style.add_button}
                      onClick={() =>
                        handleAddFormFieldArrayItem(
                          elem.backend_field,
                          elem.type
                        )
                      }
                    >
                      Add {elem.label}
                    </Button>
                  )}
                  <ImageSection
                    imageArray={imageArray}
                    setImageArray={setImageArray}
                    elem={elem}
                    handleAddFile={handleAddFile}
                    setShowImage={setShowImage}
                    setShowIcon={setShowIcon}
                    handleDeleteFormFieldArrayItem={
                      handleDeleteFormFieldArrayItem
                    }
                    multiFilePage={pageName === PAGE_NAME_OBJ.portfolio}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
        {((!imageSrc && isCropper) || !isCropper) && (
          <button type="submit" className={style.form_button}>
            {CONSTANTS.SAVE}
            <Save />
          </button>
        )}
      </form>
      {imageSrc && isCropper && (
        <StyledCropEasy
          showImage={showImage}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setShowImage={setShowImage}
        />
      )}
    </div>
  );
};
