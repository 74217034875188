import * as React from "react";
import { useDispatch } from "react-redux";
import { CONSTANTS } from "constants/general";
import { useLocation } from "react-router";

import {
  BootstrapDialog,
  ConfirmButton,
  RefuseButton,
  BootstrapDialogActions,
  BootstrapDialogContent,
  StyledDialogTitle,
} from "./styledComponents";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

function BootstrapDialogTitle(props) {
  
  const { children, onClose, ...other } = props;

  return (
    <StyledDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
}

const ModalWindow = ({ open, setOpen, id, deleteRequest, pageLimit, currentPage }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get(CONSTANTS.SEARCH);
    setOpen(false);
    dispatch(deleteRequest({id, pageLimit, currentPage, search}));
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ width: "500px" }}
        >
          {CONSTANTS.DELETE}
        </BootstrapDialogTitle>
        <BootstrapDialogContent dividers>
          <Typography gutterBottom>
            {CONSTANTS.ARE_YOU_SURE_DELETE}
          </Typography>
        </BootstrapDialogContent>
        <BootstrapDialogActions>
          <RefuseButton autoFocus onClick={handleClose}>
            {CONSTANTS.CANCEL}
          </RefuseButton>
          <ConfirmButton autoFocus onClick={handleSubmit}>
            {CONSTANTS.CONFIRM}
          </ConfirmButton>
        </BootstrapDialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalWindow;
