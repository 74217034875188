import { createAsyncThunk } from "@reduxjs/toolkit";
import Notify from "helpers/Notify";
import { socialLinkApi } from "service/socialLink";
import { CONSTANTS } from "constants/general";

export const getSocialLinks = createAsyncThunk(
  "/socialLinks",
  async ({currentPage, pageLimit, search}, { rejectWithValue }) => {
    try {
      const res = await socialLinkApi.allSocialLinkAdmin(currentPage, pageLimit, search);
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const deleteSocialLink = createAsyncThunk(
  "/socialLinks",
  async ({id, currentPage, pageLimit, search}, { rejectWithValue, dispatch }) => {
    try {
      const res = await socialLinkApi.deleteSocialLink(id);

      Notify(CONSTANTS.SUCCESS, CONSTANTS.REMOVED_SOCIAL_LINK);

      dispatch(getSocialLinks({ currentPage, pageLimit, search }));
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);
