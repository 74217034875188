import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const teamMembersSchema = [
  {
    id: "name",
    name: "name",
    label: CONSTANTS.NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_NAME,
    backend_field: "name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "surname",
    name: "surname",
    label: CONSTANTS.SURNAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_SURNAME,
    backend_field: "surname",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "position",
    name: "position",
    label: CONSTANTS.POSITION,
    type: "text",
    placeholder: CONSTANTS.ENTER_POSITION,
    backend_field: "position",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "image_information",
    name: "image_information",
    label: CONSTANTS.IMAGE_INFORMATION,
    type: "text",
    placeholder: CONSTANTS.ENTER_TEXT_ALONGSIDE_IMAGES,
    backend_field: "image_information",
    validation: Yup.string(),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
