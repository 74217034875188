import { createSlice } from "@reduxjs/toolkit";
import { getSocialLinks, deleteSocialLink } from "actions/socialLinksAction";

const initialState = {
  edit: "",
  socialLinks: [],
  count: 0,
  status: null,
}

export const socialLinksSlice = createSlice ({
  name: "socialLinks",
  initialState,
  reducers: {
    setSocialLinkId: (state, { payload }) => {
      state.edit = payload;
    },
  },
  extraReducers: {
    [getSocialLinks.pending]: (state) => {
      state.status = "loading";
    },
    [getSocialLinks.fulfilled]: (state, { payload }) => {
      state.socialLinks = payload?.socialLinks;
      state.count = payload?.count;
    },
    [getSocialLinks.rejected]: (state) => {
      state.status = "rejected";
    },
  },
})

export const { setSocialLinkId } = socialLinksSlice.actions;

export default socialLinksSlice.reducer;