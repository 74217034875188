import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Menu, List, ListItem, Divider } from "@mui/material";

import { logout } from "features/auth";
import { Button } from "components/Button";

import Logo from "assets/images/headerUserbox/icon.svg";
import { CONSTANTS } from "constants/general";

const HeaderUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Button
        variant="text"
        click={handleClick}
        customClasses="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple
      >
        <div className="d-block p-0 avatar-icon-wrapper">
          <div className="avatar-icon rounded">
            <img src={Logo} alt="NextStack LLC" />
          </div>
        </div>

        <div className="d-none d-xl-block">
          <div className="font-weight-bold pt-2 pb-1 line-height-1">name</div>
          <span className="text-black-50">email</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={faAngleDown} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        classes={{ list: "p-0" }}
        onClose={handleClose}
      >
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3"
          >
            <ListItem
              button
              className="d-block text-left"
              onClick={() => {
                handleClose();
                navigate("/change-password");
              }}
            >
              {CONSTANTS.CHANGE_PASSWORD}
            </ListItem>
            <ListItem button className="d-block text-left" onClick={authLogout}>
              {CONSTANTS.LOG_OUT}
            </ListItem>
          </List>
          <Divider className="w-100" />
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
