import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import { getCroppedImg } from './utils/canvasUtils'
import { Close, Save } from "@mui/icons-material";
import { styles } from './utils/styles'

const CropEasy = ({ classes, showImage, imageSrc, setImageSrc, setShowImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  function urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      urltoFile(croppedImage, `${showImage.name}`,'text/plain')
        .then((file) => {
          setShowImage(file);
          setImageSrc(null);
        });
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const closeCroppedImage = useCallback(() => {
    setImageSrc(null)
  }, [])

  return (
    <div className={classes.cropper}>
      <Button
        onClick={closeCroppedImage}
        variant="contained"
        color="secondary"
        classes={{ root: classes.cropCloseButton }}
        className={classes.cropCloseButtonPosition }
      >
        <span className={classes.closeSpan}><Close /></span>
      </Button>
      <React.Fragment>
        <div className={classes.cropContainer}>
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            showGrid={false}
          />
        </div>
        <div className={classes.controls}>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="secondary"
            classes={{ root: classes.cropButton }}
          >
            <span className={classes.saveSpan}>Save <Save /></span>
          </Button>
        </div>
      </React.Fragment>
    </div>
  )
}

const StyledCropEasy = withStyles(styles)(CropEasy)

export default StyledCropEasy;
