import { createSlice } from "@reduxjs/toolkit";
import { getTeamDetails } from "actions/teamDetailsAction";

const initialState = {
  edit: "",
  teamPageDetails: [],
  status: null,
};

export const teamDetailsSlice = createSlice({
  name: "teamDetails",
  initialState,
  reducers: {
    setEditTeamDetails: (state, { payload }) => {
      state.edit = payload;
    },
  },
  extraReducers: {
    [getTeamDetails.pending]: (state) => {
      state.status = true;
    },
    [getTeamDetails.fulfilled]: (state, { payload }) => {
      state.teamPageDetails = payload.teamPageDetails;
      state.status = false;
    },
    [getTeamDetails.rejected]: (state) => {
      state.status = false;
    },
  },
});

export const { setEditTeamDetails } = teamDetailsSlice.actions;

export default teamDetailsSlice.reducer;
