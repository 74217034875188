import { createSlice } from "@reduxjs/toolkit";
import { getContacts } from "actions/contactsAction";

const initialState = {
  edit: "",
  contacts: [],
  status: null,
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setEditContactsId: (state, { payload }) => {
      state.edit = payload;
    },
  },
  extraReducers: {
    [getContacts.pending]: (state) => {
      state.status = "loading";
    },
    [getContacts.fulfilled]: (state, { payload }) => {
      state.contacts = payload.contacts;
      state.status = "solved";
    },
    [getContacts.rejected]: (state) => {
      state.status = "rejected";
    },
  },
});

export const { setEditContactsId } = contactsSlice.actions;

export default contactsSlice.reducer;
