import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFormId } from "features/formSlice";
import SectionBody from "components/SectionBody/SectionBody";
import { getFormCategories, deleteFormCategory } from "actions/formAction";
import { CreateEditFormCategory } from "./CreateEditFormCategory";
import { useSearchParams } from "hooks/useSearchParams";

const FormCategories = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const dispatch = useDispatch();

  const formCategories = useSelector(
    (state) => state.formCategories.allFormCategories
  );
  const count = useSelector((state) => state.formCategories.count);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const handleCreate = () => {
    setIsCreateEdit(true);
    setIsEdit(false);
    dispatch(setFormId(""));
  };

  const handleEdit = (data) => {
    setIsCreateEdit(true);
    setIsEdit(true);
    dispatch(setFormId(data));
  };

  useEffect(() => {
    dispatch(getFormCategories({currentPage, pageLimit, search}));
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={formCategories}
          deleteHandler={deleteFormCategory}
          createHandler={handleCreate}
          setEditId={setFormId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isDescription={false}
          count={count}
          showPagination={isPageination}
          hasImage={false}
          form={true}
          handleEdit={handleEdit}
        />
        {isCreateEdit && (
          <div className="creater_component">
            <CreateEditFormCategory
              isEdit={isEdit}
              setIsCreateEdit={setIsCreateEdit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormCategories;
