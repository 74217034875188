import instance from "./baseRequest";

export const authApi = {
  login: async ({ email, password, rem }) => {
    const res = await instance.post("/admin/auth/login", { email, password });

    rem
      ? localStorage.setItem("access_token", res.data.token)
      : sessionStorage.setItem("access_token", res.data.token);

    return res;
  },
  logout: async () => await instance.get("/admin/auth/logout"),

  changePassword: async ({old_password, password, confirm_password}) => await instance.post("/admin/auth/change-password",
    {old_password, password, confirm_password}
  )
};
