import instance, { formDataInstance } from "./baseRequest";

export const portfolioApi = {
  createNewPortfolio: async (data) => {
    await formDataInstance.post("/admin/projects/store", data);
  },
  updatePortfolio: async (id, data) => {
    return await formDataInstance.put(`/admin/projects/update/${id}`, data);
  },
  updatePortfolioOrders: async (data) => {
    return await instance.put(`/admin/projects/update-orders`, data);
  },
  deletePortfolio: async (id) => {
    return await instance.delete(`/admin/projects/delete/${id}`);
  },
  getPortfolioById: async (id) => {
    return await instance.get(`/v1/projects/${id}`);
  },
  allPortfolioAdmin: async (page, limit, search = null) => {
    return await instance.get("/admin/projects/", {
      params: {
        page,
        limit,
        search,
      },
    });
  },
  getAllPortfolio: async (category_id, page, limit) => {
    return await instance.get(`/v1/projects/by-category/${category_id}`, {
      params: {
        page,
        limit,
      },
    });
  },
};
