import { createAsyncThunk } from "@reduxjs/toolkit";
import { formCategoryApi } from "service/formCategory";
import Notify from "helpers/Notify";
import { NOTIFY, getNotificationMessages } from "constants/notifyType";
import { CONSTANTS } from "constants/general";

export const getFormCategories = createAsyncThunk(
  "/forms",
  async ({currentPage, pageLimit, search}, { rejectWithValue }) => {
    try {
      const res = await formCategoryApi.allFormCategoryAdmin(currentPage, pageLimit, search);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteFormCategory = createAsyncThunk(
  "/forms/delete",
  async ({id, currentPage, pageLimit, search }, { dispatch, rejectWithValue }) => {
    try {
      await formCategoryApi.deleteFormCategory(id);
      Notify(
        NOTIFY.type.success,
        getNotificationMessages(CONSTANTS.CATEGORY, CONSTANTS.DELETE)
      );
      dispatch(getFormCategories({ currentPage, pageLimit, search }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createFormCategory = createAsyncThunk(
  "/forms/create",
  async ({ data, currentPage, pageLimit, search }, { dispatch, rejectWithValue }) => {
    try {
      await formCategoryApi.createNewFormCategory(data);
      Notify(NOTIFY.type.success, getNotificationMessages(CONSTANTS.CATEGORY, CONSTANTS.ADD));
      dispatch(getFormCategories({ currentPage, pageLimit, search }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateFormCategory = createAsyncThunk(
  "/forms/update",
  async ({ id, data,  currentPage, pageLimit, search }, { dispatch, rejectWithValue }) => {
    try {
      await formCategoryApi.updateFormCategory(id, data);
      Notify(
        NOTIFY.type.success,
        getNotificationMessages(CONSTANTS.CATEGORY, CONSTANTS.UPDATE)
      );
      dispatch(getFormCategories({ currentPage, pageLimit, search }));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
