import React, { useState } from "react";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ViewModal from "components/ViewModal";
import ModalWindow from "components/ModalWindow";
import ViewTestimonial from "../ViewTestimonial";
import Image from "components/Image";

import style from "./BodyElement.module.scss";

import DeleteIcon from "@mui/icons-material/Delete";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Edit } from "@mui/icons-material";



const BodyElement = ({ data, deleteHandler, editHandler, id, handleView }) => {
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);

  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((item) => item._id === data.project_id);

  const handleViewPopup = () => {
    setOpenView(true);
    handleView();
  };

  return (
    <div className={style.element_wrapper}>
      <Image
        image={data.icon ? data.icon : data.image}
        styles={style.element_image}
      />
      <div className={style.title_wrapper}>
        <div className={style.title_label}>Name</div>
        <div className={style.title}>
          {data?.name} {data?.last_name}
        </div>
      </div>
      <div className={style.title_wrapper}>
        <div className={style.title_label}>Description</div>
        <div className={style.title}> {data?.description} </div>
      </div>
      <div className={style.actions}>
        <div className={style.action_view} onClick={handleViewPopup}>
          <FontAwesomeIcon icon={faEye} />
        </div>
        <ViewModal open={openView} setOpen={setOpenView} name="Testimonial">
          <ViewTestimonial />
        </ViewModal>
        <div className={style.action_edit} onClick={editHandler}>
          <Edit />
        </div>
        <div className={style.action_remove}>
          <DeleteIcon
            className={style.delete_icon}
            onClick={() => setOpen(true)}
          />
          <ModalWindow
            open={open}
            setOpen={setOpen}
            deleteRequest={deleteHandler}
            id={id}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyElement;
