import React, { useState } from "react";

import { Stack, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ModalWindow from "components/ModalWindow";

import style from "./ItemsList.module.scss";

const CategoryItem = ({ elem, handleDelete, handleEdit }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={style.categories_field}>
      <Stack direction="row" spacing={1}>
        <Chip
          label={elem.title}
          onDelete={() => setOpen(true)}
          deleteIcon={<DeleteIcon />}
          variant="outlined"
          className={style.clip}
        />
      </Stack>
      <ModalWindow
        open={open}
        setOpen={setOpen}
        id={elem._id}
        deleteRequest={handleDelete}
      />
      <div className={style.edit}>
        <EditIcon onClick={() => handleEdit(elem)} />
      </div>
    </div>
  );
};

export default CategoryItem;
