import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { aboutItemApi } from "service/aboutItem";

import { FormCreator } from "components";
import { ourValuesSchema } from "./ourValuesSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditOurValue = () => {
  const location = useLocation();

  const editAboutItemId = useSelector(state => state?.aboutItem?.edit?._id);
  const editContent = useSelector((state) => state.aboutItem.edit);

  const [createNewAboutItem, editAboutItem] = useActionHelper({
    createNewItemApiCall: aboutItemApi.createNewAboutItem,
    updateItemApiCall: aboutItemApi.updateAboutItem,
    editId: editAboutItemId,
  });

  const apiCall = location.pathname.includes("create") ? createNewAboutItem : editAboutItem;

  const [initialValues, validation] = formikValidation(ourValuesSchema);

  return (
    <FormCreator
      pageName="aboutItem"
      apiCall={apiCall}
      schema={ourValuesSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
