import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import CardBody from "./CardBody";
import Filter from "./Filter";
import { sortByDate } from "helpers";

import { Pagination } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import style from "./SectionBody.module.scss";

const Card = ({
  data,
  deleteHandler,
  createHandler,
  setEditId,
  showFilter = true,
  showPagination = true,
  pageLimit = 10,
  setPageLimit = () => {},
  currentPage = 0,
  count = 0,
  setCurrentPage = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [sortedData, setSortedData] = useState([]);

  const paginationCount = Math.ceil(count / pageLimit);

  const filterHandler = (e) => {
    setFilterValue(e.target.value);
  };

  const filter = (value) => {
    console.log("Call to the API for filtering", value);
  };

  const paginationHandler = (event, value) => {
    setCurrentPage(value - 1);
  };

  const editHandler = (selectedData) => {
    dispatch(setEditId(selectedData));
    navigate("edit");
  };

  const handleView = (selectedData) => {
    dispatch(setEditId(selectedData));
  };

  const handleChange = (event) => {
    setCurrentPage(0);
    setPageLimit(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      filter(filterValue);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [filterValue]);

  useEffect(() => {
    if (data) {
      const sorted = sortByDate(data);
      setSortedData(sorted);
    }
  }, [data]);

  return (
    <>
      <div className={style.section_header}>
        <div className={style.create_button} onClick={createHandler}>
          Create
        </div>
      </div>
      <div className={style.row_wrapper}>
        {sortedData?.map((elem) => (
          <div key={elem?._id} className={style.table_row}>
            <CardBody
              data={elem}
              deleteHandler={deleteHandler}
              editHandler={() => editHandler(elem)}
              handleView={() => handleView(elem)}
              id={elem._id}
            />
          </div>
        ))}
      </div>
      {showPagination && (
        <div className={style.pagination}>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={pageLimit}
            onChange={handleChange}
            autoWidth
            label="Age"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Pagination count={paginationCount} onChange={paginationHandler} />
        </div>
      )}
    </>
  );
};

export default Card;
