import instance from "./baseRequest";

export const serviceApi = {
  createNewService: async (data) => {
    await instance.post('/admin/services/store', data);
  },
  updateService: async (id, data) => {
    return await instance.put(`/admin/services/update/${id}`, data);
  },
  updateServiceOrders: async (data) => {
    return await instance.put(`/admin/services/update-service`, data);
  },
  deleteService: async (id) => {
    return await instance.delete(`/admin/services/delete/${id}`);
  },
  getServiceById: async (id) => {
    return await instance.get(`/v1/services/${id}` );
  },
  getAllServiceAdmin: async (page, limit, search=null) => {
    return await instance.get('/admin/services', {
      params: {
        page,
        limit,
        search
      }
    });
  },
  getAllService: async (page, limit) => {
    return await instance.get(`/v1/services`, {
      params: {
        page,
        limit
      }
    } );
  },
}
