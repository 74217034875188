import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const socialLinksSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "link",
    name: "link",
    label: CONSTANTS.LINK,
    type: "text",
    placeholder: CONSTANTS.ENTER_LINK,
    backend_field: "link",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "icon",
    name: "icon",
    label: CONSTANTS.ICON,
    type: "file",
    placeholder: CONSTANTS.ICON,
    backend_field: "icon",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
