export const CONSTANTS = {
  IMAGES: "Images",
  IMAGES_FIELD: 'images',
  REQUIRED: "Required",
  RELATED_PROJECTS: "Related projects",
  RELATED_BLOGS: "Related blogs",
  PAGE_REMOVED_SUCCESS: "Page removed successfully",
  CATEGORY: "Category",
  DELETE: "delete",
  ADD: "add",
  UPDATE: "update",
  SUCCESS: "success",
  REMOVED_SOCIAL_LINK: "Removed social link",
  REMOVED_TESTIMONIAL: "Removed testimonial",
  REMOVED_CATEGORY: "Removed category",
  ADDED_CATEGORY: "Added category",
  UPDATED_CATEGORY: "Updated category",
  TITLE: "Title",
  NAME: "Name",
  ENTER_TITLE: "Enter title",
  ICON: "Icon",
  DESCRIPTION: "Description",
  ENTER_DESCRIPTION: "Enter description",
  META_TITLE: "Meta title",
  ENTER_META_TITLE: "Enter meta title",
  META_DESCRIPTION: "Meta description",
  ENTER_META_DESCRIPTION: "Enter meta description",
  SLUG: "Slug",
  ENTER_SLUG: "Enter slug",
  SHORT_DESCRIPTION: "Short description",
  ENTER_SHORT_DESCRIPTION: "Enter short description",
  DEADLINE: "Deadline",
  ENTER_DEADLINE: "Enter deadline",
  LOCATION: "Location",
  ENTER_LOCATION: "Enter location",
  CONTACT_INFORMATION: "Contact information",
  ENTER_CONTACT_INFORMATION: "Enter contact information",
  IMAGE_INFORMATION: "Image information",
  ENTER_TEXT_ALONGSIDE_IMAGES: "Enter text alongside images",
  IMAGE: "Image",
  ENTER_IMAGE: "Enter image",
  ENTER_IMAGES: "Enter images",
  ENTER_RELATED_BLOGS: "Enter related blogs separated by comma",
  MAIN_TITLE: "Main title",
  ENTER_MAIN_TITLE: "Enter main title",
  SERVICE_TYPE: "Service type",
  ENTER_SERVICE_TYPE: "Enter service type",
  ENTER_NAME: "Enter name",
  CLIENT: "Client",
  ENTER_CLIENT_NAME: "Enter client name",
  INDUSTRY: "Industry",
  ENTER_CLIENT_INDUSTRY: "Enter client industry",
  APP_STORE: "App store",
  ENTER_CLIENT_APP_STORE: "Enter client app store",
  GOOGLE_PLAY: "Google play",
  ENTER_CLIENT_GOOGLE_PLAY: "Enter client google play",
  WEBSITE: "Website",
  ENTER_CLIENT_WEBSITE: "Enter client website",
  CATEGORIES: "Categories",
  ENTER_CATEGORIES: "Enter categories separated by comma",
  SERVICES: "Services",
  ENTER_SERVICES: "Enter services separated by comma",
  OTHER_SERVICES: "Other services",
  ENTER_OTHER_SERVICES: "Enter other services",
  ENTER_RELATED_PROJECTS: "Enter related projects separated by comma",
  LAST_NAME: "Last name",
  ENTER_LAST_NAME: "Enter last name",
  TEXT: "Text",
  ENTER_TEXT: "Enter text",
  POSITION: "Position",
  ENTER_POSITION: "Enter position",
  PROJECT: "Project",
  PROJECT_TYPE: "Project type custom",
  PROJECT_NAME: "Project name",
  ENTER_PROJECT_NAME: "Enter project name",
  ENTER_PROJECT: "Enter project",
  ADDRESS: "Address",
  ENTER_ADDRESS: "Enter address",
  PHONE_NUMBER: "Phone number",
  ENTER_PHONE_NUMBER: "Enter phone number",
  EMAIL: "Email",
  ENTER_EMAIL_ADDRESS: "Enter email address",
  WHAT_WE_DO: "What We Do",
  ENTER_WHAT_WE_DO: "Enter What We Do",
  WHAT_WE_DONT: "What We Don't",
  ENTER_WHAT_WE_DONT: "Enter What We Don't",
  SURNAME: "Surname",
  ENTER_SURNAME: "Enter surname",
  OLD_IMAGES: "Old Images",
  LINK: "Link",
  ENTER_LINK: "Enter link",
  FAILED_TO_DETECT: "failed to detect the orientation",
  OTHER_SERVICE: "Other",
  VALID_TEXT: "text",
  YEREVAN_KIMITAS: "Yerevan, Komitas 15/2",
  CELL_PHONE_NUMBER: "+37477123456",
  BLOG_CREATED_SUCCESSFULLY: "Blog created successfully",
  ADD_BUTTON: "Add",
  CHANGE_PASSWORD: "Change password",
  LOG_OUT: "Log out",
  OLD_PASSWORD: "Old password",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm password",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  ARE_YOU_SURE_DELETE: "Are you sure you want to delete this?",
  SEARCH: "search",
  SAVE: "Save",
  PASSWORD_CHANGE_SUCCESS: "Password changed successfully",
  ABOUT_NEXTSTACK: "About NextStack",
  CONTACT_DETAILS: "Contact Details",
  LINK_ADDED_SUCCESS: "Link added",
  PORTFOLIO_DETAILS: "Portfolio Details",
  BACK: "Back",
};

export const fieldNamesObj = {
  projectId: "project_id",
  projectName: "project_name",
};

export const errMessagesObj = {
  requiredProjectId: "Project ID is required",
  requiredProjectName: "Project name is required",
};

export const TITLE = "title";
export const DESCRIPTION = "description";
