import { serviceTypeApi } from "service/serviceType";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";

export const getServiceType = createAsyncThunk(
  "/serviceType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await serviceTypeApi.allServiceTypeAdmin();
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteServiceType = createAsyncThunk(
  "/serviceType",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await serviceTypeApi.deleteServiceType(data.id);

      Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);

      dispatch(getServiceType());
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createServiceType = createAsyncThunk(
  "/serviceType",
  async ({ data }, {rejectWithValue, dispatch}) => {
    try{
      const res = await serviceTypeApi.createNewServiceType(data);
      Notify("success", "Created new service type");
      dispatch(getServiceType());
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateServiceType = createAsyncThunk(
  "/serviceType",
  async ({ id, data }, {rejectWithValue, dispatch}) => {
    try{
      const res = await serviceTypeApi.updateServiceType(id, data);
      Notify("success", "Created new service type");
      dispatch(getServiceType());
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
