import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getContacts } from "actions/contactsAction";
import { FormCreator } from "components";
import { useActionHelper } from "hooks/useActionHelper";
import { contactApi } from "service/contact";
import { setEditContactsId } from "features/contactsSlice";

import { Edit } from "@mui/icons-material";
import { contactsDetailsSchema } from "./contactsDetailsSchema";
import { formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";

const CreateEditContactsDetails = () => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);

  const contacts = useSelector((state) => state.contacts.contacts);
  const editContactId = useSelector((state) => state.contacts.edit._id);
  const editContent = useSelector((state) => state.contacts.edit);

  const contact = contacts[0];
  const [_, editContact] = useActionHelper({
    createNewItemApiCall: contactApi.createNewContact,
    updateItemApiCall: contactApi.updateContact,
    editId: editContactId,
    navigateTo: "contacts",
    setIsEdit,
  });

  const handleEdit = () => {
    dispatch(setEditContactsId(contact));
    setIsEdit(true);
  };

  const apiCall = editContact;

  const [initialValues, validation] = formikValidation(contactsDetailsSchema);

  useEffect(() => {
    dispatch(getContacts());
  }, [isEdit]);

  return (
    <div className="contacts_field">
      {!isEdit ? (
        <div className="contacts_contant">
          <div className="contacts_title">
            <h2>{CONSTANTS.CONTACT_DETAILS}</h2>
          </div>
          <div className="contacts_edit_button">
            <Edit onClick={handleEdit} />
          </div>
          <div className="contacts_container">
            <div className="contact_subtitle">{CONSTANTS.ADDRESS}</div>
            {contact?.address?.map((address, index) => (
              <div className="contact_text" key={index}>
                {address}
              </div>
            ))}
            <div className="contact_subtitle">{CONSTANTS.PHONE_NUMBER}</div>
            <div className="contact_text">{contact?.phone_number}</div>
            <div className="contact_subtitle">{CONSTANTS.EMAIL}</div>
            {contact?.email_address?.map((email, index) => (
              <div className="contact_text" key={index}>
                {email}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <FormCreator
          pageName="contacts"
          apiCall={apiCall}
          isFormData={false}
          showClose={true}
          getApiCall={getContacts}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isArray={true}
          schema={contactsDetailsSchema}
          editContent={editContent}
          initialValues={initialValues}
          validation={validation}
        />
      )}
    </div>
  );
};

export default CreateEditContactsDetails;
