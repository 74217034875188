import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { pageApi } from "service/page";

import { FormCreator } from "components";
import { pagesSchema } from "./pageSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditPage = () => {
  const location = useLocation();

  const editPageId = useSelector(state => state.pages.edit._id);
  const editContent = useSelector((state) => state.pages.edit);

  const [createNewPage, editPage] = useActionHelper({
    createNewItemApiCall: pageApi.createNewPage,
    updateItemApiCall: pageApi.updatePage,
    editId: editPageId,
  });

  const apiCall = location.pathname.includes("create") ? createNewPage : editPage;

  const [initialValues, validation] = formikValidation(pagesSchema);

  return (
    <FormCreator
      pageName="pages"
      apiCall={apiCall}
      schema={pagesSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
