import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const serviceSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "main_title",
    name: "main_title",
    label: CONSTANTS.MAIN_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_MAIN_TITLE,
    backend_field: "main_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "short_description",
    name: "short_description",
    label: CONSTANTS.SHORT_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_SHORT_DESCRIPTION,
    backend_field: "short_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_title",
    name: "meta_title",
    label: CONSTANTS.META_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_META_TITLE,
    backend_field: "meta_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_description",
    name: "meta_description",
    label: CONSTANTS.META_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_META_DESCRIPTION,
    backend_field: "meta_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "service_type",
    name: "service_type",
    label: CONSTANTS.SERVICE_TYPE,
    type: "select",
    placeholder: CONSTANTS.ENTER_SERVICE_TYPE,
    valid: "text",
    backend_field: "service_type",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "icon",
    name: "icon",
    label: CONSTANTS.ICON,
    type: "file",
    placeholder: CONSTANTS.ICON,
    backend_field: "icon",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
