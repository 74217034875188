import React from "react";

import Card from "../UI/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import CategoryItem from "./CategoryItem";

import style from "./ItemsList.module.scss";

const ItemsList = ({
  closeHandel,
  handleDelete,
  handleEdit,
  categories,
  createHandler,
  buttonName = "Create Category",
}) => {

  return (
    <div className={style.page_view}>
      <Card>
        <div className={style.categories_wrapper}>
          <div className={style.close}>
            <FontAwesomeIcon
              icon={faClose}
              className="opacity-5"
              onClick={closeHandel}
            />
          </div>
          {categories?.map((elem) => {
            return (
              <CategoryItem
                key={elem._id}
                elem={elem}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            );
          })}
          <div className={style.create_button} onClick={createHandler}>
            {buttonName}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ItemsList;
