import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getRecognitions, deleteRecognition } from "actions/recognitionsAction";
import { setEditRecognitionsId } from "features/recognitionsSlice";
import SectionBody from "components/SectionBody/SectionBody";
import { useSearchParams } from "hooks/useSearchParams";

const Recognition = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const recognitions = useSelector((state) => state.recognitions.recognitions);
  const count = useSelector((state) => state.recognitions.count);

  const {search} = useSearchParams();

  const dispatch = useDispatch();

  const isPageination = count > 10;

  const createHandler = () => {
    dispatch(setEditRecognitionsId(""));
    navigate("create");
  };

  useEffect(() => {
    dispatch(getRecognitions({ currentPage, pageLimit, search }));
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={recognitions}
          deleteHandler={deleteRecognition}
          createHandler={createHandler}
          setEditId={setEditRecognitionsId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isDescription={false}
          count={count}
          showPagination={isPageination}
        />
      </div>
    </div>
  );
};

export default Recognition;
