import { createAsyncThunk } from "@reduxjs/toolkit";
import { recognitionApi } from "service/recognition";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";

export const getRecognitions = createAsyncThunk(
  "/recognitions/getRecognitions",
  async ( {currentPage, pageLimit, search}, { rejectWithValue }) => {
    try {
      const res = await recognitionApi.allRecognitionAdmin(currentPage, pageLimit, search);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteRecognition = createAsyncThunk(
  "/recognitions/deleteRecognition",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      await recognitionApi.deleteRecognition(data.id);

      const { currentPage, pageLimit, search } = data;

      Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
      dispatch(getRecognitions({ currentPage, pageLimit, search }));
    } catch (err) {
        return rejectWithValue(err.message);
    }
  }
);
