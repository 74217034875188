export const NOTIFY = {
  type: {
    success: "success",
    error: "error",
  },
  message: {
    add: "successfully added",
    delete: "successfully removed",
    update: "successfully updated",
  },
};

export const getNotificationMessages = (name, key) => {
  return name + " " + NOTIFY.message[key];
};
