import Routes from "Routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//Styles
import "./App.css";
import "./assets/base.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
