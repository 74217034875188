import instance from "./baseRequest";

export const serviceTypeApi = {
  createNewServiceType: async (data) => {
    await instance.post('/admin/services/service-types/store', data);
  },
  updateServiceType: async (id, data) => {
    return await instance.put(`/admin/services/service-types/update/${id}`, data);
  },
  deleteServiceType: async (id) => {
    return await instance.delete(`/admin/services/service-types/delete/${id}`);
  },
  allServiceTypeAdmin: async (page, limit) => {
    return await instance.get('/admin/services/service-types', {
      params: {
        page,
        limit
      }
    });
  },
  getAllServiceType: async () => {
    return await instance.get(`/v1/services/service-types`);
  },
}