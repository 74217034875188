import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { teamMembersApi } from "service/teamMembers";

import { FormCreator } from "components";
import { teamMembersSchema } from "./teamMembersSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditTeamMember = () => {
  const location = useLocation();

  const editTeamMemberId = useSelector(state => state.teamMembers.edit._id);
  const categories = useSelector(state => state.categories.allCategories);
  const editContent = useSelector((state) => state.teamMembers.edit);

  const [createNewTeamMember, editTeamMember] = useActionHelper({
    createNewItemApiCall: teamMembersApi.createNewTeamMember,
    updateItemApiCall: teamMembersApi.updateTeamMember,
    editId: editTeamMemberId,
  });

  const apiCall = location.pathname.includes("create") ? createNewTeamMember : editTeamMember;

  const [initialValues, validation] = formikValidation(teamMembersSchema);

  return (
    <FormCreator
      pageName="teamMembers"
      apiCall={apiCall}
      options={categories}
      schema={teamMembersSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
