import { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { setSocialLinkId } from "features/socialLinksSlice";
import { getSocialLinks, deleteSocialLink } from "actions/socialLinksAction";
import SectionBody from "components/SectionBody/SectionBody";
import { useSearchParams } from "hooks/useSearchParams";

const SocialLinks = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const socialLinks = useSelector((state) => state.socialLinks.socialLinks);
  const count = useSelector((state) => state.socialLinks.count);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const handleCreate = () => {
    dispatch(setSocialLinkId(""));
    navigate("create");
  };

  useEffect(() => {
    dispatch(getSocialLinks({currentPage, pageLimit, search}));
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={socialLinks}
          deleteHandler={deleteSocialLink}
          createHandler={handleCreate}
          setEditId={setSocialLinkId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isDescription={false}
          count={count}
          showPagination={isPageination}
          pageName="SocialLinks"
        />
      </div>
    </div>
  );
};

export default SocialLinks;
