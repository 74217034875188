import instance from "./baseRequest";

export const careerApi = {
  createNewCareer: async (data) => {
    await instance.post('/admin/careers/store', data);
  },
  updateCareer: async (id, data) => {
    return await instance.put(`/admin/careers/update/${id}`, data);
  },
  deleteCareer: async (id) => {
    return await instance.delete(`/admin/careers/delete/${id}`);
  },
  getCareerById: async (id) => {
    return await instance.get(`/v1/careers/${id}`);
  },
  allCareerAdmin: async (page, limit, search=null) => {
    return await instance.get('/admin/careers', {
      params: {
        page,
        limit,
        search
      }
    });
  },
}