import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const testimonialsSchema = [
  {
    id: "name",
    name: "name",
    label: CONSTANTS.NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_NAME,
    backend_field: "name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "last_name",
    name: "last_name",
    label: CONSTANTS.LAST_NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_LAST_NAME,
    backend_field: "last_name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "text",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "text",
    name: "text",
    label: CONSTANTS.TEXT,
    type: "text",
    placeholder: CONSTANTS.ENTER_TEXT,
    backend_field: "text",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "position",
    name: "position",
    label: CONSTANTS.POSITION,
    type: "text",
    placeholder: CONSTANTS.ENTER_POSITION,
    backend_field: "position",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "project_name",
    name: "project_name",
    label: CONSTANTS.PROJECT_NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_PROJECT_NAME,
    backend_field: "project_name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    isConditional: true,
  },
  {
    id: "project_id",
    name: "project_id",
    label: CONSTANTS.PROJECT,
    type: "select",
    placeholder: CONSTANTS.ENTER_PROJECT,
    backend_field: "project_id",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    valid: "text",
    isConditional: true,
  },
  {
    id: "project_type",
    name: "is_custom_project",
    label: CONSTANTS.PROJECT_TYPE,
    type: "checkbox",
    backend_field: "is_custom_project",
    validation: Yup.boolean(),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
