import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const pagesSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_title",
    name: "meta_title",
    label: CONSTANTS.META_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_META_TITLE,
    backend_field: "meta_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_description",
    name: "meta_description",
    label: CONSTANTS.META_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_META_DESCRIPTION,
    backend_field: "meta_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "slug",
    name: "slug",
    label: CONSTANTS.SLUG,
    type: "text",
    placeholder: CONSTANTS.ENTER_SLUG,
    backend_field: "Slug",
    validation: Yup.string().required("Required"),
  },
  {
    id: "icon",
    name: "icon",
    label: CONSTANTS.ICON,
    type: "file",
    placeholder: CONSTANTS.ICON,
    backend_field: "icon",
    validation: Yup.string(),
  },
]
