import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const blogSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "CKEditor",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "short_description",
    name: "short_description",
    label: CONSTANTS.SHORT_DESCRIPTION,
    type: "text",
    placeholder: CONSTANTS.ENTER_SHORT_DESCRIPTION,
    backend_field: "short_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_title",
    name: "meta_title",
    label: CONSTANTS.META_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_META_TITLE,
    backend_field: "meta_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_description",
    name: "meta_description",
    label: CONSTANTS.META_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_META_DESCRIPTION,
    backend_field: "meta_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "slug",
    name: "slug",
    label: CONSTANTS.SLUG,
    type: "text",
    placeholder: CONSTANTS.SLUG,
    backend_field: "slug",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "related_blogs",
    name: "related_blogs",
    label: CONSTANTS.RELATED_BLOGS,
    type: "blogArray",
    placeholder: CONSTANTS.ENTER_RELATED_BLOGS,
    backend_field: "related_blogs",
    validation: Yup.array().of(Yup.string()),
    valid: "text",
  },
  {
    id: "image_information",
    name: "image_information",
    label: CONSTANTS.IMAGE_INFORMATION,
    type: "text",
    placeholder: CONSTANTS.ENTER_TEXT_ALONGSIDE_IMAGES,
    backend_field: "image_information",
    validation: Yup.string(),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
