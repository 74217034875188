import { createAsyncThunk } from "@reduxjs/toolkit";
import { contactApi } from "service/contact";

const adaptContacts = (data) => ({
  ...data,
  contacts: data.contacts.map((contact) => ({
    ...contact,
    address:
      typeof contact.address === "string"
        ? [contact.address]
        : contact.address
        ? contact.address
        : [],
  })),
});

export const getContacts = createAsyncThunk(
  "/contacts/getContacts",
  async (_, { rejectWithValue }) => {
    try {
      const res = await contactApi.allContactAdmin();
      return adaptContacts(res.data);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
