import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProjects } from "actions/projectsAction";
import Image from "components/Image";

const ViewTestimonial = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.testimonials?.edit);
  const projects = useSelector((state) => state.projects.projects);

  const project = projects.find((project) => project._id === data.project_id);

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  return (
    <div className="view_field no_padding">
      <div className="view_contant view_testimonial_container">
        <div className="view_container width">
          <div className="view_testimonial_personal">
            <div className="view_testimonial_image_filed">
              <Image image={data?.image} styles="view_testimonial_image" />
            </div>
            <div className="view_testimonial_person">
              <div className="view_testimonial_subtitle">Name</div>
              <div className="view_testimonial_text">{data?.name}</div>
              <div className="view_testimonial_subtitle">Last Name</div>
              <div className="view_testimonial_text">{data?.last_name}</div>
              <div className="view_testimonial_subtitle">Position</div>
              <div className="view_testimonial_text">{data?.position}</div>
            </div>
          </div>
          <div className="view_subtitle">Description</div>
          <div className="view_text">{data?.description}</div>
          <div className="view_subtitle">Text</div>
          <div className="view_text">{data?.text}</div>
          <div className="view_subtitle">Project</div>
          <div className="view_text">{project ? project?.name : "None"}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewTestimonial;
