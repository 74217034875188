import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: '',
  allServices: [],
  count: 0,
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setAllServices(state, { payload }) {
      state.allServices = payload.services;
      state.count = payload.count
    },
    setEditServiceId(state, payload) {
      state.edit = payload.payload;
    },
    clearEditService(state) {
      state.edit = '';
    },
  },
});

export const {
  setAllServices,
  setEditServiceId,
  clearEditService,
} = serviceSlice.actions;

export default serviceSlice.reducer;
