import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const careerSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "short_description",
    name: "short_description",
    label: CONSTANTS.SHORT_DESCRIPTION,
    type: "text",
    placeholder: CONSTANTS.ENTER_SHORT_DESCRIPTION,
    backend_field: "short_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    titleLabel: CONSTANTS.TITLE,
    type: "careerArray",
    descriptionLabel: CONSTANTS.DESCRIPTION,
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.array().of(
      Yup.object({
        title: Yup.string().required(CONSTANTS.REQUIRED),
        description: Yup.string().required(CONSTANTS.REQUIRED),
      })).min(1, CONSTANTS.REQUIRED),
  },
  {
    id: "meta_title",
    name: "meta_title",
    label: CONSTANTS.META_TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_META_TITLE,
    backend_field: "meta_title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "meta_description",
    name: "meta_description",
    label: CONSTANTS.META_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_META_DESCRIPTION,
    backend_field: "meta_description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "deadline",
    name: "deadline",
    label: CONSTANTS.DEADLINE,
    type: "date",
    placeholder: CONSTANTS.ENTER_DEADLINE,
    backend_field: "deadline",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "location",
    name: "location",
    label: CONSTANTS.LOCATION,
    type: "textLocation",
    placeholder: CONSTANTS.ENTER_LOCATION,
    backend_field: "location",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "contact_information",
    name: "contact_information",
    label: CONSTANTS.CONTACT_INFORMATION,
    type: "textContactInfo",
    placeholder: CONSTANTS.ENTER_CONTACT_INFORMATION,
    backend_field: "contact_information",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "image_information",
    name: "image_information",
    label: CONSTANTS.IMAGE_INFORMATION,
    type: "text",
    placeholder: CONSTANTS.ENTER_TEXT_ALONGSIDE_IMAGES,
    backend_field: "image_information",
    validation: Yup.string(),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
