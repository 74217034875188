import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import SectionBody from "components/SectionBody/SectionBody";
import { portfolioApi } from "service/project";
import Notify from "helpers/Notify";
import { setAllPortfolios, setEditPortfolioId } from "features/portfolioSlice";
import { PAGE_NAME_OBJ } from "constants/pageName";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const Portfolio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPortfolios = useSelector((state) => state.portfolio.allPortfolios);
  const count = useSelector((state) => state.portfolio.count);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [portfolios, setPortfolios] = useState();
  const [filteredType, setFilteredType] = useState([]);

  const isPageination = count > 10;

  const { search } = useSearchParams();

  const deleteHandler = (data) => {
    portfolioApi
      .deletePortfolio(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        portfolioApi.allPortfolioAdmin().then((res) => {
          dispatch(setAllPortfolios(res.data));
        });
      });
  };

  const createHandler = () => {
    dispatch(setEditPortfolioId(""));
    navigate("create");
  };

  useEffect(() => {
    const fetchPortfolio = async () => {
      const { data } = await portfolioApi.allPortfolioAdmin(
        currentPage,
        pageLimit,
        search
      );
      return data;
    };

    fetchPortfolio().then((res) => {
      dispatch(setAllPortfolios(res));
    });
  }, [currentPage, pageLimit, search]);

  useEffect(() => {
    if (filteredType.length === 0) {
      setPortfolios(allPortfolios);
    } else {
      const filteredPortfolio = filteredType?.reduce((acc, type) => {
        allPortfolios.forEach((project) => {
          if (project.categories.find((elem) => elem === type?._id)) {
            acc.push(project);
          }
        });

        return acc;
      }, []);

      setPortfolios(filteredPortfolio);
    }
  }, [filteredType, allPortfolios]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={portfolios}
          deleteHandler={deleteHandler}
          createHandler={createHandler}
          setEditId={setEditPortfolioId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isView={true}
          count={count}
          showPagination={isPageination}
          isName={true}
          hasType="categories"
          setFilteredType={setFilteredType}
          isDraggable={true}
          section={PAGE_NAME_OBJ.portfolio}
          isProject={true}
        />
      </div>
    </div>
  );
};
