import instance from "./baseRequest";

export const aboutApi = {
  createNewAbout: async (data) => {
    await instance.post('/admin/about/store', data);
  },
  updateAbout: async (id, data) => {
    return await instance.put(`/admin/about/update/${id}`, data);
  },
  deleteAbout: async (id) => {
    return await instance.delete(`/admin/about/delete/${id}`);
  },
  getAllAbout: async () => {
    return await instance.get(`/v1/about/`);
  },
  allAboutAdmin: async () => {
    return await instance.get('/admin/about/');
  },
}