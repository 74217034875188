import instance from "./baseRequest";

export const teamDetailsApi = {
  createNewTeamDetail: async (data) => {
    await instance.post('/admin/teams/team-page-details/store', data);
  },
  updateTeamDetail: async (id, data) => {
    return await instance.put(`/admin/teams/team-page-details/update/${id}`, data);
  },
  deleteTeamDetails: async (id) => {
    return await instance.delete(`/admin/teams/team-page-details/delete/${id}`);
  },
  allTeamDetailAdmin: async (page, limit) => {
    return await instance.get('/admin/teams/team-page-details', {
      params: {
        page,
        limit
      }
    });
  },
  getAllTeamDetail: async (page, limit) => {
    return await instance.get(`/v1/teams/team-page-details`, {
      params: {
        page,
        limit
      }
    });
  },
}