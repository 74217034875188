import instance from "./baseRequest";

export const recognitionApi = {
  createNewRecognition: async (data) => {
    await instance.post('/admin/recognitions/store', data);
  },
  updateRecognition: async (id, data) => {
    return await instance.put(`/admin/recognitions/update/${id}`, data);
  },
  deleteRecognition: async (id) => {
    return await instance.delete(`/admin/recognitions/delete/${id}`);
  },
  getRecognitionById: async (id) => {
    return await instance.get(`/v1/recognitions/${id}`);
  },
  allRecognitionAdmin: async (page, limit, search) => {
    return await instance.get('/admin/recognitions', {
      params: {
        page,
        limit,
        search,
      }
    });
  },
  getAllRecognition: async (page, limit) => {
    return await instance.get(`/v1/recognitions`, {
      params: {
        page,
        limit
      }
    });
  },
}
