import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";

export const useActionHelper = ({
  createNewItemApiCall,
  updateItemApiCall,
  editId,
  navigateTo,
  isEdit = false,
  setIsEdit,
  customNotify = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createNewItem = (data) => {
    if (isEdit) {
      dispatch(createNewItemApiCall({ data }));
      if (setIsEdit) setIsEdit(false);
    } else {
      createNewItemApiCall(data)
        .then((res) => {
          Notify(CONSTANTS.SUCCESS, customNotify ?? CONSTANTS.SUCCESS);
          return res;
        })
        .then(() => {
          if (!navigateTo) navigate(-1);
        });
    }
  };

  const editItem = (data) => {
    if (isEdit) {
      dispatch(updateItemApiCall({ id: editId, data }));
      if (setIsEdit) setIsEdit(false);
    } else {
      updateItemApiCall(editId, data)
        .then((res) => {
          Notify(CONSTANTS.SUCCESS, CONSTANTS.SUCCESS);
          return res;
        })
        .then(() => {
          if (!navigateTo) {
            navigate(-1)
          };
          if (setIsEdit) setIsEdit(false);
        });
    }
  };

  return [createNewItem, editItem];
};
