import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Notify from "helpers/Notify";
import { aboutItemApi } from "service/aboutItem";
import { setAllAboutItems, setEditAboutItemId } from "features/aboutItemSlice";

import SectionBody from "components/SectionBody/SectionBody";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const OurValues = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ourValues = useSelector((state) => state.aboutItem.allAboutItems);
  const count = useSelector((state) => state.aboutItem.count);
  const val = useSelector((state) => state.aboutItem);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const fetchOurValues = async () => {
    const { data } = await aboutItemApi.allAboutItemAdmin(
      currentPage,
      pageLimit,
      search
    );
    dispatch(setAllAboutItems(data));
  };

  const deleteHandler = (data) => {
    aboutItemApi
      .deleteAboutItem(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        fetchOurValues();
      });
  };

  const createHandler = () => {
    dispatch(setEditAboutItemId(""));
    navigate("create");
  };

  useEffect(() => {
    fetchOurValues({ currentPage, pageLimit, search });
  }, [currentPage, pageLimit, search]);

  return (
    <SectionBody
      data={ourValues}
      deleteHandler={deleteHandler}
      createHandler={createHandler}
      setEditId={setEditAboutItemId}
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      count={count}
      showPagination={isPageination}
    />
  );
};
