import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const contactsDetailsSchema = [
  {
    id: "address",
    name: "address",
    label: CONSTANTS.ADDRESS,
    type: "array",
    placeholder: CONSTANTS.ENTER_ADDRESS,
    backend_field: "address",
    validation: Yup.array().of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
  {
    id: "phone_number",
    name: "phone_number",
    label: CONSTANTS.PHONE_NUMBER,
    type: "text",
    placeholder: CONSTANTS.ENTER_PHONE_NUMBER,
    backend_field: "phone_number",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "email_address",
    name: "email_address",
    label: CONSTANTS.EMAIL,
    type: "array",
    placeholder: CONSTANTS.ENTER_EMAIL_ADDRESS,
    backend_field: "email_address",
    validation: Yup.array().of(
      Yup.string().email().required(CONSTANTS.REQUIRED)
    ),
  },
];
