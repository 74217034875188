import instance from "./baseRequest";

export const pageApi = {
  createNewPage: async (data) => {
    await instance.post('/admin/pages/store', data);
  },
  updatePage: async (id, data) => {
    return await instance.put(`/admin/pages/update/${id}`, data);
  },
  deletePage: async (data) => {
    return await instance.delete(`/admin/pages/delete/${data.id}`);
  },
  getPageBySlug: async (slug) => {
    return await instance.get(`/v1/pages/${slug}`);
  },
  allPageAdmin: async (page, limit, search=null) => {
    return await instance.get('/admin/pages', {
      params: {
        page,
        limit,
        search
      }
    });
  }
}
