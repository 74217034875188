import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

import { setHeaderSearchHover } from "features/headerSlice";

const HeaderSearch = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const { headerSearchHover } = useSelector((state) => state.header);

  const updateSearch = useCallback(
    (e) => {
      e.preventDefault();

      query.set("search", search);

      navigate(`?${query.toString()}`);
    },
    [query.toString(), search]
  );

  const toggleHeaderSearchHover = () => {
    dispatch(setHeaderSearchHover(!headerSearchHover));
  };

  return (
    <form onSubmit={updateSearch} className="header-search-wrapper">
      <div
        className={clsx("search-wrapper", {
          "is-active": headerSearchHover,
        })}
      >
        <label className="icon-wrapper" htmlFor="header-search-input">
          <FontAwesomeIcon icon={faSearch} />
        </label>
        <TextField
          onFocus={toggleHeaderSearchHover}
          onBlur={toggleHeaderSearchHover}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          id="header-search-input"
          name="header-search-input"
          type="search"
          placeholder="Search terms..."
          variant="outlined"
        />
      </div>
    </form>
  );
};

export default HeaderSearch;
