import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Button } from "@mui/material";

import { getServiceType } from "actions/serviceTypeAction";
import Image from "components/Image";

const ViewCareer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.career?.edit);

  const handleCloseViewPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getServiceType());
  }, []);

  return (
    <div className="view_field">
      <div className="view_cancel_button">
        <Button onClick={handleCloseViewPage}>Back</Button>
      </div>
      <div className="view_contant">
        <div className="view_title">
          <h2>Career Details</h2>
        </div>
        <div className="view_container">
          <div className="view_subtitle">Image</div>
          <div className="view_image_filed">
          <Image image={data?.image} />
          </div>
          <div className="view_subtitle">Title</div>
          <div className="view_text">{data?.title}</div>
          <div className="view_subtitle">Description</div>
          {data?.description?.map((item) => (
            <div key={item?._id} className="view_description_field">
              <div className="view_description_title">{item?.title}</div>
              <div className="view_text">{item?.description}</div>
            </div>
          ))}
          <div className="view_subtitle">Contact Information</div>
          <div className="view_text">{data?.contact_information}</div>
          <div className="view_subtitle">Location</div>
          <div className="view_text">{data?.location}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewCareer;
