import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import SectionBody from "components/SectionBody/SectionBody";
import { teamMembersApi } from "service/teamMembers";
import Notify from "helpers/Notify";
import { setEditTeamMembersId, setAllTeams } from "features/teamMembersSlice";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const TeamMembers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teamMembers.teams);
  const count = useSelector((state) => state.teamMembers.count);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const deleteHandler = (data) => {
    teamMembersApi
      .deleteTeamMember(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        teamMembersApi.allTeamMembersAdmin().then((res) => {
          dispatch(setAllTeams(res.data));
        });
      });
  };

  const createHandler = () => {
    navigate("create");
    dispatch(setEditTeamMembersId(""));
  };

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const { data } = await teamMembersApi.allTeamMembersAdmin(
        currentPage,
        pageLimit,
        search
      );
      return data;
    };

    fetchTeamMembers().then((res) => {
      dispatch(setAllTeams(res));
    });
  }, [currentPage, pageLimit, search]);

  return (
    <>
      <SectionBody
        data={teams}
        deleteHandler={deleteHandler}
        createHandler={createHandler}
        setEditId={setEditTeamMembersId}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        count={count}
        showPagination={isPageination}
      />
    </>
  );
};
