import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { Pagination } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import BodyElement from "./BodyElement";
import Categories from "pages/Portfolio/Categories";
import ServiceType from "pages/Services/ServiceType";
import { portfolioApi } from "service/project";
import { serviceApi } from "service/service";
import { PAGE_NAME_OBJ } from "constants/pageName";

import style from "./SectionBody.module.scss";
import { sortByDate } from "helpers";
import { pageCounts } from "utiles";

const SectionBody = ({
  data,
  deleteHandler,
  createHandler,
  setEditId,
  showPagination = true,
  pageLimit = 10,
  setPageLimit = () => {},
  currentPage = 0,
  setCurrentPage = () => {},
  isDescription,
  isView = false,
  count,
  isName,
  setFilteredType,
  customBodyElmClass,
  hasType = "",
  hasImage = true,
  form = false,
  handleEdit = null,
  pageName,
  isDraggable = false,
  section = "",
  isRound,
  isProject,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [sortedData, setSortedData] = useState([]);

  const paginationCount = Math.ceil(count / pageLimit);
  const [serviceType, categories] = ["serviceType", "categories"];
  const [currentCard, setCurrentCard] = useState();

  const dragStartHandler = (e, service) => {
    if (isDraggable) {
      setCurrentCard(service);
    }
  };
  const dragLeaveHandler = (e) => {
    if (isDraggable) {
      e.target.style.opacity = "1";
    }
  };
  const dragEndHandler = (e) => {
    if (isDraggable) {
      e.target.style.opacity = "1";
    }
  };
  const dragOverHandler = (e) => {
    e.preventDefault();
    if (isDraggable) {
      e.target.style.opacity = "0.5";
    }
  };
  const dropHandler = (e, service) => {
    e.preventDefault();
    if (isDraggable) {
      e.target.style.opacity = "1";
      const updatedServices = [...sortedData];
      const dragIndex = sortedData.indexOf(currentCard);
      const dropIndex = sortedData.indexOf(service);
      const removedElement = updatedServices.splice(dragIndex, 1);
      updatedServices.splice(dropIndex, 0, ...removedElement);
      setSortedData(updatedServices);
      const reOrderedServices = [];
      updatedServices?.forEach((service, index) => {
        reOrderedServices.push({
          id: service?._id,
          order: index + pageLimit * currentPage + 1,
        });
      });
      if (section === PAGE_NAME_OBJ.portfolio) {
        portfolioApi.updatePortfolioOrders(reOrderedServices);
      }
      if (section === PAGE_NAME_OBJ.service) {
        serviceApi.updateServiceOrders(reOrderedServices);
      }
    }
  };

  const filter = (value) => {
    console.log("Call to the API for filtering", value);
  };

  const paginationHandler = (_, value) => {
    setCurrentPage(value - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const editHandler = (selectedData) => {
    dispatch(setEditId(selectedData));
    navigate("edit");
  };

  const viewHandler = (selectedData) => {
    dispatch(setEditId(selectedData));
    navigate("view");
  };

  const handleChange = (event) => {
    setPageLimit(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      filter(filterValue);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [filterValue]);

  useEffect(() => {
    if (data) {
      if (isDraggable) {
        setSortedData(data);
      } else {
        const sorted = sortByDate(data);
        setSortedData(sorted);
      }
    }
  }, [data]);

  return (
    <>
      <div
        className={`${style.section_header} ${
          hasType && style.section_header_flex
        }`}
      >
        {hasType === categories && (
          <Categories setFilteredType={setFilteredType} />
        )}
        {hasType === serviceType && (
          <ServiceType setFilteredType={setFilteredType} />
        )}
        <div className={style.create_button} onClick={createHandler}>
          Create
        </div>
      </div>
      <div className={style.row_wrapper}>
        {sortedData?.map((elem) => (
          <div
            onDragStart={(e) => dragStartHandler(e, elem)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
            onDragOver={(e) => dragOverHandler(e)}
            onDrop={(e) => dropHandler(e, elem)}
            key={elem?._id}
            className={style.table_row}
            draggable={isDraggable}
          >
            <BodyElement
              data={elem}
              deleteHandler={deleteHandler}
              id={elem?._id}
              editHandler={() => (form ? handleEdit(elem) : editHandler(elem))}
              viewHandler={() => viewHandler(elem)}
              isDescription={isDescription}
              isView={isView}
              isName={isName}
              hasImage={hasImage}
              pageName={pageName}
              customBodyElmClass={customBodyElmClass}
              isRound={isRound}
              pageLimit={pageLimit}
              currentPage={currentPage}
              isProject={isProject}
            />
          </div>
        ))}
      </div>
      {showPagination && (
        <div className={style.pagination}>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={pageLimit}
            onChange={handleChange}
            autoWidth
            label="Age"
          >
            {pageCounts.map((item) => {
              if (item.value === "") {
                return (
                  <MenuItem key={item.label} value="">
                    <em>{item.label}</em>
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
          {(paginationCount > 1) &&
            <Pagination count={paginationCount} onChange={paginationHandler} />
          }
        </div>
      )}
    </>
  );
};

export default SectionBody;
