import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const changePasswordSchema = [
  {
    id: "old_password",
    name: "old_password",
    label: CONSTANTS.OLD_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.OLD_PASSWORD,
    backend_field: "old_password",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "password",
    name: "password",
    label: CONSTANTS.PASSWORD,
    type: "password",
    placeholder: CONSTANTS.PASSWORD,
    backend_field: "password",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "confirm_password",
    name: "confirm_password",
    label: CONSTANTS.CONFIRM_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.CONFIRM_PASSWORD,
    backend_field: "confirm_password",
    validation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required(CONSTANTS.REQUIRED),
  },
]
