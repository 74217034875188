import { createSlice } from "@reduxjs/toolkit";
import {
  getTestimonials,
  deleteTestimonials,
} from "actions/testimonialsActions";

const initialState = {
  edit: "",
  testimonials: [],
  count: 0,
};

export const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    setEditTestimonialsId(state, payload) {
      state.edit = payload.payload;
    },
  },
  extraReducers: {
    [getTestimonials.pending]: (state) => {
      state.getTestimonialsLoader = true;
    },
    [getTestimonials.fulfilled]: (state, { payload }) => {
      state.testimonials = payload?.testimonials;
      state.count = payload?.count;
      state.getTestimonialsLoader = false;
    },
    [getTestimonials.rejected]: (state) => {
      state.getTestimonialsLoader = false;
    },
  },
});

export const selectTestimonials = (state) => state.testimonials;
export const { setEditTestimonialsId } = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
