import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { careerApi } from "service/career";

import { FormCreator } from "components";
import { careerSchema } from "./careerSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditCareer = () => {
  const location = useLocation();

  const editCareerId = useSelector(state => state.career.edit);
  const editContent = useSelector((state) => state.career.edit);

  const [createNewCareer, editCareer] = useActionHelper({
    createNewItemApiCall: careerApi.createNewCareer,
    updateItemApiCall: careerApi.updateCareer,
    editId: editCareerId._id,
  });

  const apiCall = location.pathname.includes("create") ? createNewCareer : editCareer;

  const [initialValues, validation] = formikValidation(careerSchema);

  return (
    <FormCreator
      pageName="career"
      apiCall={apiCall}
      options={editCareerId}
      schema={careerSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
