import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import SectionBody from "components/SectionBody/SectionBody";
import { careerApi } from "service/career";
import Notify from "helpers/Notify";
import { setAllCareers, setEditCareerId } from "features/careerSlice";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const Career = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCareers = useSelector((state) => state.career.allCareers);
  const count = useSelector((state) => state.career.count);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const deleteHandler = (data) => {
    careerApi
      .deleteCareer(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res.data;
      })
      .then(() => {
        careerApi.allCareerAdmin().then((res) => {
          dispatch(setAllCareers(res.data));
        });
      });
  };

  const createHandler = () => {
    dispatch(setEditCareerId(""));
    navigate("create");
  };

  useEffect(() => {
    const fetchCareer = async () => {
      const { data } = await careerApi.allCareerAdmin(
        currentPage,
        pageLimit,
        search
      );
      return data;
    };

    fetchCareer().then((res) => {
      dispatch(setAllCareers(res));
    });
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={allCareers}
          deleteHandler={deleteHandler}
          createHandler={createHandler}
          setEditId={setEditCareerId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isView={true}
          count={count}
          showPagination={isPageination}
          customBodyElmClass="career_card"
        />
      </div>
    </div>
  );
};
