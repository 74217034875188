import React, { useEffect, useState } from "react";
import * as DOMPurify from 'dompurify';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalWindow from "components/ModalWindow";
import Image from "components/Image";

import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "constants/general";

import style from "./BodyElement.module.scss";

const BodyElement = ({
  data,
  deleteHandler,
  editHandler,
  viewHandler,
  isDescription = true,
  isView,
  id,
  isName = false,
  hasImage,
  pageName,
  customBodyElmClass,
  isRound = false,
  pageLimit,
  currentPage,
  isProject,
}) => {
  const [open, setOpen] = useState(false);
  const [cleanDescription, setCleanDescription] = useState();

  const isPerson = data.surname ? true : false;

  const socialStyles = pageName ? style.element_wrapper_background : "";

  useEffect(() => {
    if(isProject) {
      setCleanDescription(DOMPurify.sanitize(
        data?.description, 
        { USE_PROFILES: { html: true } }
      ));
    }
  },[isProject]);

  return (
    <div
      className={`${style.element_wrapper} ${
        !isDescription && style.element_wrapper_img_grid
      } ${!hasImage && style.element_wrapper_grid} ${
        style[customBodyElmClass]
      }`}
    >
      {hasImage && (
        <Image
          image={data.icon ? data.icon : data.image}
          styles={`${style.element_image} ${isRound && style.round_photo}`}
          socialStyles={socialStyles}
        />
      )}
      <div className={style.title_wrapper}>
        <div className={style.title_label}>
          {isPerson || isName ? CONSTANTS.NAME : CONSTANTS.TITLE}
        </div>
        <div className={style.title}>
          {isPerson
            ? `${data?.name} ${data?.surname}`
            : isName
            ? data.name
            : data?.title}
        </div>
      </div>
      {isPerson ? (
        <div className={style.title_wrapper}>
          <div className={style.title_label}>{CONSTANTS.POSITION}</div>
          <div className={style.title}> {data?.position} </div>
        </div>
      ) : (
        isDescription && (
          <div className={style.description_wrapper}>
            {!(typeof data.description === "object") && (
              <div className={style.description_label}>{CONSTANTS.DESCRIPTION}</div>
            )}
            {!(typeof data.description === "object") &&
              (data?.short_description || data?.description) && (
                <div className={style.short_description}>
                  {data?.short_description || 
                    (isProject ? 
                      (<div dangerouslySetInnerHTML={{__html: cleanDescription || ""}} />) : 
                      data?.description
                    )
                  }
                </div>
              )}
          </div>
        )
      )}
      <div className={style.actions}>
        {isView && (
          <div className={style.action_view} onClick={viewHandler}>
            <FontAwesomeIcon icon={faEye} />
          </div>
        )}
        <div className={style.action_edit} onClick={editHandler}>
          <Edit />
        </div>
        <div className={style.action_remove}>
          <DeleteIcon
            className={style.delete_icon}
            onClick={() => setOpen(true)}
          />
          <ModalWindow
            open={open}
            setOpen={setOpen}
            id={id}
            deleteRequest={deleteHandler}
            pageLimit={pageLimit}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyElement;
