import {getRotatedImage} from "../components/crop/utils/canvasUtils";
import { CONSTANTS } from "constants/general";

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const onFileCropperChange = async (e, setImageSrc) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0]
    let imageDataUrl = await readFile(file)

    try {
      // apply rotation if needed
      imageDataUrl = await getRotatedImage(imageDataUrl);
    } catch (e) {
      console.warn(CONSTANTS.FAILED_TO_DETECT)
    }

    setImageSrc(imageDataUrl)
  }
}
