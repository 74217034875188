export const pageCounts = [
  {
    label: "None",
    value: "",
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];