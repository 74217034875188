import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { socialLinkApi } from "service/socialLink";

import { FormCreator } from "components";
import { socialLinksSchema } from "./socialLinksSchema";
import { formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";

export const CreateEditSocialLinks = () => {
  const location = useLocation();

  const editSocialLinkID = useSelector(state => state.socialLinks.edit._id);
  const editContent = useSelector((state) => state.socialLinks.edit);

  const [createNewTechnology, editTechnology] = useActionHelper({
    createNewItemApiCall: socialLinkApi.createNewSocialLink,
    updateItemApiCall: socialLinkApi.updateSocialLink,
    editId: editSocialLinkID,
    customNotify: CONSTANTS.LINK_ADDED_SUCCESS,
  });

  const apiCall = location.pathname.includes("create") ? createNewTechnology : editTechnology;

  const [initialValues, validation] = formikValidation(socialLinksSchema);

  return (
    <FormCreator
      pageName="socialLinks"
      apiCall={apiCall}
      schema={socialLinksSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
