import { FORM_ITEM_TYPES } from "../constants/pageName";

export const formikValidation = (schema, showHandWriteService = false) => {
  const initialValues = {};
  let validation = {};

  schema.forEach((elem) => {
    if (elem.type === FORM_ITEM_TYPES.careerArray) {
      initialValues[elem.name] = [
        {
          title: "",
          description: "",
        },
      ];
    } else if (
      elem.type === FORM_ITEM_TYPES.array ||
      elem.type === FORM_ITEM_TYPES.oldArray ||
      elem.type === FORM_ITEM_TYPES.selectOfArray ||
      elem.type === FORM_ITEM_TYPES.projectOfArray ||
      elem.type === FORM_ITEM_TYPES.blogOfArray ||
      elem.type === FORM_ITEM_TYPES.secondSelect
    ) {
      initialValues[elem.name] = [];
    } else {
      initialValues[elem.name] = "";
    }

    if (!showHandWriteService) {
      const { other_services, ...rest } = validation;
      validation = rest;
    } else {
      const { services, ...rest } = validation;
      validation = rest;
    }

    validation[elem.name] = elem.validation;
  });

  return [initialValues, validation];
};

export const formErrorHelper = ({ 
  formik, 
  elementName, 
  isBoolean = false, 
  index, 
  type, 
  name 
}) => {
  if (isBoolean) {
    if (type === FORM_ITEM_TYPES.careerArray) {
      return formik?.touched?.[elementName]?.[index]?.[name] && 
        Boolean(formik?.errors?.[elementName]?.[index]?.[name])
    }else if (type === FORM_ITEM_TYPES.array) {
      return formik?.touched?.[elementName]?.[index] && 
        Boolean(formik?.errors?.[elementName]?.[index])
    }else {
      return formik.touched[elementName] && Boolean(formik.errors[elementName]);
    }
  } else {
    if (type === FORM_ITEM_TYPES.careerArray) {
      return formik?.touched?.[elementName]?.[index]?.[name] && 
        formik?.errors?.[elementName]?.[index]?.[name]
    }else if (type === FORM_ITEM_TYPES.array) {
      return formik?.touched?.[elementName]?.[index] && 
      formik?.errors?.[elementName]?.[index]
    }else {
      return formik.touched[elementName] && formik.errors[elementName]
    }
  }
};
