import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { setAllBlogs, setEditBlogId } from "features/blogSlice";
import SectionBody from "components/SectionBody/SectionBody";
import { blogApi } from "service/blog";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

import style from "./Blog.module.scss";

export const Blog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allBlogs = useSelector((state) => state.blog.allBlogs);
  const count = useSelector((state) => state.blog.count);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const deleteHandler = (data) => {
    blogApi
      .deleteBlog(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        blogApi.allBlogs().then((res) => {
          dispatch(setAllBlogs({ blogs: res.data.blog }));
        });
      });
  };

  const createHandler = () => {
    dispatch(setEditBlogId(""));
    navigate("create");
  };

  useEffect(() => {
    const fetchBlog = async () => {
      const { data } = await blogApi.allBlogsAdmin(
        currentPage,
        pageLimit,
        search
      );
      return data;
    };

    fetchBlog().then((res) => {
      dispatch(setAllBlogs(res));
    });
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <SectionBody
          data={allBlogs}
          deleteHandler={deleteHandler}
          createHandler={createHandler}
          setEditId={setEditBlogId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isView={true}
          count={count}
          showPagination={isPageination}
        />
      </div>
    </div>
  );
};
