import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Notify from "helpers/Notify";
import { pageApi } from "service/page";
import SectionBody from "components/SectionBody/SectionBody";
import { setAllPages, setEditPageId } from "features/pageSlice";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const Pages = ({ showFilter, showPagination }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPages = useSelector((state) => state.pages.allPages);

  const {search} = useSearchParams();

  const deleteHandler = (id) => {
    pageApi
      .deletePage(id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        pageApi.allPageAdmin(0, 50).then((res) => {
          dispatch(setAllPages(res.data));
        });
      });
  };

  const createHandler = () => {
    dispatch(setEditPageId(""));
    navigate("create");
  };

  useEffect(() => {
    const fetchPage = async () => {
      const { data } = await pageApi.allPageAdmin(0, 50, search);
      return data;
    };

    fetchPage().then((res) => {
      dispatch(setAllPages(res));
    });
  }, [search]);

  return (
    <SectionBody
      data={allPages}
      deleteHandler={deleteHandler}
      createHandler={createHandler}
      setEditId={setEditPageId}
      showFilter={showFilter}
      showPagination={showPagination}
    />
  );
};
