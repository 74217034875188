import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const ourValuesSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "icon",
    name: "icon",
    label: CONSTANTS.ICON,
    type: "file",
    placeholder: CONSTANTS.ICON,
    backend_field: "icon",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
