import { createAsyncThunk } from "@reduxjs/toolkit";
import { teamMembersApi } from "service/teamMembers";

export const getTeamMembers = createAsyncThunk(
  "/teamMembers/getTeamMembers", 
  async (_, { rejectWithValue }) => {
    try {
      const res = await teamMembersApi.allTeamMembersAdmin();
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
)