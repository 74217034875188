import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edit: '',
  allPages: [],
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setAllPages(state, payload) {
      state.allPages = payload.payload.pages;
    },
    setEditPageId(state, payload) {
      state.edit = payload.payload;
    },
    clearEditPage(state) {
      state.edit = '';
    },
  },
});

export const {
  setAllPages,
  setEditPageId,
  clearEditPage,
} = pageSlice.actions;

export default pageSlice.reducer;
