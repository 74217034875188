import React from "react";
import { useSelector } from "react-redux";

import { useActionHelper } from "hooks/useActionHelper";

import { FormCreator } from "components";
import { createFormCategory, updateFormCategory } from "actions/formAction";
import { formCategoriesSchema } from "./formCategoriesSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditFormCategory = ({ isEdit, setIsCreateEdit}) => {
  const editFormCategoryId = useSelector(state => state.formCategories.edit._id);
  const editContent = useSelector((state) => state.formCategories.edit);

  const [createNewFormCategory, editFormCategory] = useActionHelper({
    createNewItemApiCall: createFormCategory,
    updateItemApiCall: updateFormCategory,
    editId: editFormCategoryId,
    navigateTo: "formCategories",
    isEdit: true,
    setIsEdit: setIsCreateEdit,
  });

  const apiCall = !isEdit ? createNewFormCategory : editFormCategory;

  const [initialValues, validation] = formikValidation(formCategoriesSchema);

  return (
    <FormCreator
      pageName="formCategories"
      apiCall={apiCall}
      isFormData={false}
      showClose={true}
      setIsEdit={setIsCreateEdit}
      schema={formCategoriesSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
