import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteTestimonials,
  getTestimonials,
} from "actions/testimonialsActions";
import Card from "./Card/Card";

import { setEditTestimonialsId } from "features/testimonialsSlice";
import { useSearchParams } from "hooks/useSearchParams";

const Testimonials = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const { search } = useSearchParams();

  const testimonials = useSelector(
    (state) => state?.testimonials?.testimonials
  );
  const count = useSelector((state) => state.testimonials.count);

  const isPageination = count > 10;

  const dispatch = useDispatch();

  const createHandler = () => {
    dispatch(setEditTestimonialsId(""));
    navigate("create");
  };

  useEffect(() => {
    dispatch(getTestimonials({ currentPage, pageLimit, search }));
  }, [currentPage, pageLimit, search]);

  return (
    <div className="testimonial_container">
      <div className="testimonial_contant">
        <Card
          data={testimonials}
          deleteHandler={deleteTestimonials}
          createHandler={createHandler}
          setEditId={setEditTestimonialsId}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          count={count}
          showPagination={isPageination}
        />
      </div>
    </div>
  );
};

export default Testimonials;
