import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { getAbout } from "actions/aboutNextStackAction";
import { FormCreator } from "components";
import { setEditAboutId } from "features/aboutSlice";
import { useActionHelper } from "hooks/useActionHelper";
import { aboutApi } from "service/about";

import { Edit } from "@mui/icons-material";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import imageNotFound from "assets/images/imageNotFound.jpeg";
import { nextStackSchema } from "./nextStackSchema";
import { formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const NextStack = () => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  const about = useSelector((state) => state.about.about);
  const editAboutId = useSelector((state) => state.about.edit._id);
  const editContent = useSelector((state) => state.about.edit);

  const handleEdit = () => {
    dispatch(setEditAboutId(about));
    setIsEdit(true);
  };

  const [_, editAbout] = useActionHelper({
    createNewItemApiCall: aboutApi.createNewAbout,
    updateItemApiCall: aboutApi.updateAbout,
    editId: editAboutId,
    navigateTo: "about",
    setIsEdit,
  });

  const apiCall = editAbout;

  const [initialValues, validation] = formikValidation(nextStackSchema);

  useEffect(() => {
    dispatch(getAbout());
  }, [isEdit]);

  useEffect(() => {
    if(about?.images) {
      setImageArray(about.images);
    }
  }, [about]);

  return (
    <div className="about_field">
      {!isEdit ? (
        <div className="about_contant">
          <div className="about_title">
            <h2>{CONSTANTS.ABOUT_NEXTSTACK}</h2>
          </div>
          <div className="about_edit_button">
            <Edit onClick={handleEdit} />
          </div>
          <div className="about_container">
            <div className="about_subtitle">{CONSTANTS.TITLE}</div>
            <div className="about_text">{about?.title}</div>
            <div className="about_subtitle">{CONSTANTS.DESCRIPTION}</div>
            <div className="about_text">{about?.description}</div>
            <div className="about_subtitle">{CONSTANTS.IMAGES}</div>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              loop={true}
            >
              {about?.images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={`${image_url}${image}`}
                    alt="NextStack"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageNotFound;
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <FormCreator
          pageName="about"
          apiCall={apiCall}
          showClose={true}
          getApiCall={getAbout}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          schema={nextStackSchema}
          editContent={editContent}
          initialValues={initialValues}
          validation={validation}
          setImageArray={setImageArray}
          imageArray={imageArray}
        />
      )}
    </div>
  );
};
