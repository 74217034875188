import instance from "./baseRequest";

export const formCategoryApi = {
  createNewFormCategory: async (data) => {
    await instance.post('/admin/forms/form-categories/store', data);
  },
  updateFormCategory: async (id, data) => {
    return await instance.put(`/admin/forms/form-categories/update/${id}`, data);
  },
  deleteFormCategory: async (id) => {
    return await instance.delete(`/admin/forms/form-categories/delete/${id}`);
  },
  allFormCategoryAdmin: async (page, limit, search) => {
    return await instance.get('/admin/forms/form-categories', {
      params: {
        page,
        limit,
        search
      }
    });
  },
  getFormCategoryType: async () => {
    return await instance.get(`/v1/forms/form-categories`);
  },
}
