import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormCreator } from "components";
import { useActionHelper } from "hooks/useActionHelper";
import { setEditTeamDetails } from "features/teamDetailsSlice";
import { teamDetailsApi } from "service/teamDetails";
import { getTeamDetails } from "actions/teamDetailsAction";

import { Edit } from "@mui/icons-material";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper";
import imageNotFound from "assets/images/imageNotFound.jpeg";
import { teamContentSchema } from "./teamContentSchema";
import { formikValidation } from "helpers/formikValidation";
import { CONSTANTS } from "constants/general";

const image_url = process.env.REACT_APP_API_IMAGE_URL;
const imageArraySize = 1;

const CreateEditTeamContent = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  const teamDetails = useSelector((state) => state.teamDetails.teamPageDetails);
  const editTeamDetailsId = useSelector((state) => state?.teamDetails?.edit?._id);
  const editContent = useSelector((state) => state.teamDetails.edit);

  const dispatch = useDispatch();

  const [createNewTeamDetailsItem, editTeamDetailsItem] = useActionHelper({
    createNewItemApiCall: teamDetailsApi.createNewTeamDetail,
    updateItemApiCall: teamDetailsApi.updateTeamDetail,
    editId: editTeamDetailsId,
    navigateTo: "teamDetails",
    setIsEdit,
  });

  const handleEdit = () => {
    dispatch(setEditTeamDetails(teamDetails));
    setIsEdit(true);
  };

  const apiCall = editTeamDetailsId ? editTeamDetailsItem : createNewTeamDetailsItem;

  const [initialValues, validation] = formikValidation(teamContentSchema);

  useEffect(() => {
    dispatch(getTeamDetails());
  }, [isEdit]);

  useEffect(() => {
    if(teamDetails.images) {
      setImageArray(teamDetails.images);
    }
  }, [teamDetails]);

  return (
    <div className="team_field">
      {!isEdit ? (
        <div className="team_contant">
          <div className="team_title">
            <h2>Team Content</h2>
          </div>
          <div className="team_edit_button">
            <Edit onClick={handleEdit} />
          </div>
          <div className="team_container">
            <div className="team_subtitle">{CONSTANTS.TITLE}</div>
            <div className="team_text">{teamDetails?.title}</div>
            <div className="team_subtitle">{CONSTANTS.DESCRIPTION}</div>
            <div className="team_text">{teamDetails?.description}</div>
            <div className="team_subtitle">{CONSTANTS.WHAT_WE_DO}</div>
            <div className="team_text">
              {teamDetails?.what_we_do?.map((elem, index) => (
                <div key={index}>{elem}</div>
              ))}
            </div>
            <div className="team_subtitle">{CONSTANTS.WHAT_WE_DONT}</div>
            <div className="team_text">
              {teamDetails?.what_we_dont?.map((elem, index) => (
                <div key={index}>{elem}</div>
              ))}
            </div>
            <div className="team_subtitle">{CONSTANTS.IMAGES}</div>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              loop={true}
            >
              {teamDetails?.images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={`${image_url}${image}`}
                    alt="NextStack"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageNotFound;
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <FormCreator
          pageName="teamDetails"
          apiCall={apiCall}
          showClose={true}
          getApiCall={getTeamDetails}
          isFormData={true}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isArray={true}
          schema={teamContentSchema}
          editContent={editContent}
          initialValues={initialValues}
          validation={validation}
          setImageArray={setImageArray}
          imageArray={imageArray}
          imageArraySize={imageArraySize}
        />
      )}
    </div>
  );
};

export default CreateEditTeamContent;
