import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Notify from "helpers/Notify";
import { serviceApi } from "service/service";
import { setAllServices, setEditServiceId } from "features/serviceSlice";
import { PAGE_NAME_OBJ } from "constants/pageName";

import SectionBody from "components/SectionBody/SectionBody";
import { CONSTANTS } from "constants/general";
import { useSearchParams } from "hooks/useSearchParams";

export const Services = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allServices = useSelector((state) => state.service.allServices);
  const count = useSelector((state) => state.service.count);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [services, setServices] = useState();
  const [filteredType, setFilteredType] = useState([]);

  const isPageination = count > 10;

  const {search} = useSearchParams();

  const deleteHandler = (data) => {
    serviceApi
      .deleteService(data.id)
      .then((res) => {
        Notify(CONSTANTS.SUCCESS, CONSTANTS.PAGE_REMOVED_SUCCESS);
        return res;
      })
      .then(() => {
        serviceApi.getAllServiceAdmin().then((res) => {
          dispatch(setAllServices(res.data));
        });
      });
  };

  const createHandler = () => {
    navigate("create");
    dispatch(setEditServiceId(""));
  };

  useEffect(() => {
    const fetchService = async () => {
      const { data } = await serviceApi.getAllServiceAdmin(
        currentPage,
        pageLimit,
        search
      );
      return data;
    };

    fetchService().then((res) => {
      dispatch(setAllServices(res));
    });
  }, [currentPage, pageLimit, search]);

  useEffect(() => {
    if (filteredType.length === 0) {
      setServices(allServices);
    } else {
      const filteredServices = filteredType?.reduce((acc, serviceId) => {
        allServices?.forEach((service) => {
          if (service.service_type === serviceId?._id) {
            acc.push(service);
          }
        });
        return acc;
      }, []);
      setServices(filteredServices);
    }
  }, [allServices, filteredType]);

  return (
    <>
      <div className="testimonial_container">
        <div className="testimonial_contant">
          <SectionBody
            data={services}
            deleteHandler={deleteHandler}
            createHandler={createHandler}
            setEditId={setEditServiceId}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            count={count}
            showPagination={isPageination}
            isView={true}
            hasType="serviceType"
            setFilteredType={setFilteredType}
            isDraggable={true}
            section={PAGE_NAME_OBJ.service}
          />
        </div>
      </div>
    </>
  );
};
