import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useActionHelper } from "hooks/useActionHelper";
import { technologyApi } from "service/technology";

import { FormCreator } from "components";
import { technologiesSchema } from "./technologiesSchema";
import { formikValidation } from "helpers/formikValidation";

export const CreateEditTechnologies = () => {
  const location = useLocation();

  const editTestimonialId = useSelector(state => state.technologies.edit._id);
  const editContent = useSelector((state) => state.technologies.edit);

  const [createNewTechnology, editTechnology] = useActionHelper({
    createNewItemApiCall: technologyApi.createNewTechnology,
    updateItemApiCall: technologyApi.updateTechnology,
    editId: editTestimonialId,
  });

  const apiCall = location.pathname.includes("create") ? createNewTechnology : editTechnology;

  const [initialValues, validation] = formikValidation(technologiesSchema);

  return (
    <FormCreator
      pageName="technologies"
      apiCall={apiCall}
      schema={technologiesSchema}
      editContent={editContent}
      initialValues={initialValues}
      validation={validation}
    />
  )
}
